import React, { useEffect, useState } from "react";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Card as MuiCard,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import { Formik } from "formik";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import OlogaSelect from "../../components/select/OlogaSelect";
import http from "../../utils/axios";

interface dropdownDTO {
  text: string;
  value: number;
}
const ToolSupplyMapping: React.FC = () => {
  const Card = styled(MuiCard)(spacing);
  const navigate = useNavigate();
  const [url, setUrl] = useState("/candidate/supply");
  const [selectedProvince, setSelectedProvince] = useState<string | "">("");
  const [selectedSector, setSelectedSector] = useState<string | "">("");
  const [sectors, setSectors] = useState<dropdownDTO[]>([]);
  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const [selectedName, setSelectedName] = useState<string | "">("");
  const [query, setQuery] = useState("");

  const columns: GridColDef[] = [
    {
      field: "candidateReference",
      headerName: "Ref",
      flex: 2,
      editable: false,
    },
    {
      field: "candidateName",
      headerName: "Nome do Beneficiario",
      flex: 2,
      editable: false,
    },
    {
      field: "province",
      headerName: "Provincia",
      flex: 2,
      editable: false,
    },
    {
      field: "district",
      headerName: "Distrito",
      flex: 2,
      editable: false,
    },
    //  {
    //   field: "candidateReference",
    //   headerName: "Localização",
    //   flex: 1,
    //   editable: false,
    // },
    {
      field: "category",
      headerName: "Categoria",
      flex: 2,
      editable: false,
    },
    {
      field: "size",
      headerName: "Dimensão",
      flex: 4,
      editable: false,
    },
    {
      field: "activitySector",
      headerName: "Sector de Actividade",
      flex: 4,
      editable: false,
    },
    // {
    //   field: "subActivitySector",
    //   headerName: "Sub-Sector de Actividade",
    //   flex: 1,
    //   editable: false,
    // },
    //  {
    //   field: "candidateReference",
    //   headerName: "Sector de Actividade",
    //   flex: 1,
    //   editable: false,
    // },
    // {
    //   field: "additionalAssistance",
    //   headerName: "Descrição dos Produtos/Serviços Prestados",
    //   flex: 1,
    //   editable: false,
    // },
    {
      field: "desiredAssistance",
      headerName: "Tipo de Assistência",
      flex: 4,
      editable: false,
    },
    {
      field: "estimatedAnnualJobCreation",
      headerName: "Classificação da Assistência",
      flex: 4,
      editable: false,
    },
    {
      field: "assistancePurpose",
      headerName: "Finalidade da Assistência Requerida",
      flex: 4,
      editable: false,
    },
    {
      field: "previouslyBenefited",
      headerName: "Candidato Anteriormente Beneficiado Assistência pela TEPMA1",
      flex: 3,
      editable: false,
    },

    // {
    //   field: "actions",
    //   headerName: "Ações",
    //   flex: 0.5,
    //   editable: false,
    //   type: "actions",
    //   getActions: (params) => [
    //     <Button
    //       size="small"
    //       variant="outlined"
    //       startIcon={<VisibilityOutlinedIcon />}
    //       onClick={() => {
    //         console.log("log:", params);
    //         navigate(`/assessments/edit`, {
    //           state: { id: params.id },
    //         });
    //       }}
    //     >
    //       View
    //     </Button>,
    //   ],
    // },
  ];

  const handleSubmit = async (values: any) => {
    try {
      if (selectedName != "" || selectedName.length > 0) {
        values["candidateName"] = selectedName;
      }
      if (selectedSector != "" || selectedSector.length > 0) {
        values["activitySector"] = selectedSector;
      }
      if (selectedProvince != "" || selectedProvince.length > 0) {
        values["province"] = selectedProvince;
      }
      console.log(values);
      let q = "";

      for (let key in values) {
        if (values.hasOwnProperty(key)) {
          let value = values[key];
          if (value) {
            q += key + "=" + value + "&";
          }
        }
      }
      console.log("query", q);
      setUrl(
        q.length > 0
          ? "/candidate/search-supply?" + q.slice(0, -1)
          : "/candidate/supply"
      );
    } catch (error: any) {
      toast.error(error.message || "Error submitting form");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ACTIVITY_SECTOR = await http.get(
          `simple-entity/findDropdown/ACTIVITY_SECTOR`
        );
        setSectors(ACTIVITY_SECTOR.data);

        const response = await http.get(`simple-entity/findDropdown/PROVINCE`);
        setDropdown_province(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [url]);

  const handleSectorChange = (event: any) => {
    console.log(event.target.value);

    setSelectedSector(event.target.value);
  };

  const handleProvinceChange = (event: any) => {
    console.log(event.target.value);

    setSelectedProvince(event.target.value);
  };
  const handleNameChange = (event: any) => {
    console.log(event.target.value);

    setSelectedName(event.target.value);
  };
  return (
    <React.Fragment>
      <OlogaPageHeader
        title={"Mapeamento de Oferta"}
        // buttonLabel="New Assessment"
        // onClick={() => navigate("/assessments/create")}
      ></OlogaPageHeader>
      <Grid container>
        <Grid item xs={12}>
          <Formik initialValues={{}} enableReinitialize onSubmit={handleSubmit}>
            {({ handleSubmit, status }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4} mb={3}>
                  <Grid item xs={12} md={3}>
                    <OlogaTextfield
                      name="candidateName"
                      label="Candidate Name"
                      // onChange={handleNameChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel>Setor de Atividade</InputLabel>
                      <Select
                        value={selectedSector}
                        onChange={handleSectorChange}
                        label="Setor de Atividade"
                      >
                        <MenuItem value="">
                          <em>Selecione um setor</em>
                        </MenuItem>
                        {sectors.map((sector) => (
                          <MenuItem key={sector.value} value={sector.text}>
                            {sector.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel>Província</InputLabel>
                      <Select
                        value={selectedProvince}
                        onChange={handleProvinceChange}
                        label="Província"
                      >
                        <MenuItem value="">
                          <em>Selecione uma província</em>
                        </MenuItem>
                        {dropdown_province.map((province) => (
                          <MenuItem key={province.value} value={province.text}>
                            {province.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <OlogaButton
                      label="Search"
                      type="submit"
                      icon={<SearchIcon />}
                      mt={3}
                      size="large"
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <Card>
            <OlogaDataGrid url={url} columns={columns} />
          </Card>
          <OlogaBackButton />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ToolSupplyMapping;
