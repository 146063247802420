import React, { useState, useEffect } from "react";
import http from "../../utils/axios";
import {
  TextField,
  FormControl,
  Typography,
  CircularProgress,
  CardContent,
  Card,
  CardHeader,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Divider,
} from "@mui/material";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";

interface Candidate {
  id: number;
  candidateReference: string;
  candidateName: string;
  companyFoundationYear: number;
  category: string;
  province: string;
  district: string;
  companyType: string;
  activitySector: string;
  numberOfEmployees: string;
  haveAcount: boolean;
}

interface IDemand {
  id: number;
  reference: string;
  responsable: string;
  description: string;
  name: string;
  subActivitySector: string;
  province: string;
  district: string;
  location: string;
  executionPhase: string;
  perpectiveDuration: string;
  pubDate: string;
  startDate: string;
  endDate: string;
}

interface dropdownDTO {
  text: string;
  value: number;
}

const MatchmakingTool: React.FC = () => {
  const [sectors, setSectors] = useState<dropdownDTO[]>([]);
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [demands, setDemands] = useState<IDemand[]>([]);
  const [selectedSector, setSelectedSector] = useState<string | "">("");
  const [selectedProvince, setSelectedProvince] = useState<string | "">("");
  const [filteredOffers, setFilteredOffers] = useState<Candidate[]>([]);
  const [filteredDemands, setFilteredDemands] = useState<IDemand[]>([]);
  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ACTIVITY_SECTOR = await http.get(
          `simple-entity/findDropdown/ACTIVITY_SECTOR`
        );
        setSectors(ACTIVITY_SECTOR.data);

        const response = await http.get(`simple-entity/findDropdown/PROVINCE`);
        setDropdown_province(response.data);

        const candidates_ = await http.get(`/candidate/list`);
        setCandidates(candidates_.data);

        const _demands = await http.get(`/demand/demands`);
        setDemands(_demands.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSectorChange = (event: any) => {
    setSelectedSector(event.target.value);
  };

  const handleProvinceChange = (event: any) => {
    setSelectedProvince(event.target.value);
  };

  const handleSearch = () => {
    const ofertas = candidates.filter(
      (o) =>
        (selectedSector === "" || o.activitySector === selectedSector) &&
        (selectedProvince === "" || o.province === selectedProvince)
    );
    setFilteredOffers(ofertas);

    const demandas = demands.filter(
      (d) =>
        (selectedSector === "" || d.subActivitySector === selectedSector) &&
        (selectedProvince === "" || d.province === selectedProvince)
    );
    setFilteredDemands(demandas);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <OlogaPageHeader title={"Ferramenta para o Matchmaking"} />

      <Card>
        <CardHeader title="Ferramenta de Matchmaking" />
        <CardContent>
          {/* Formulário de pesquisa */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Setor de Atividade</InputLabel>
                <Select
                  value={selectedSector}
                  onChange={handleSectorChange}
                  label="Setor de Atividade"
                >
                  <MenuItem value="">
                    <em>Selecione um setor</em>
                  </MenuItem>
                  {sectors.map((sector) => (
                    <MenuItem key={sector.value} value={sector.text}>
                      {sector.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Província</InputLabel>
                <Select
                  value={selectedProvince}
                  onChange={handleProvinceChange}
                  label="Província"
                >
                  <MenuItem value="">
                    <em>Selecione uma província</em>
                  </MenuItem>
                  {dropdown_province.map((province) => (
                    <MenuItem key={province.value} value={province.text}>
                      {province.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
                sx={{ marginTop: 2 }}
              >
                Pesquisar
              </Button>
            </Grid>
          </Grid>

          {/* Tabelas de Oferta e Demanda */}
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ borderRight: "2px solid #ccc", paddingRight: 2 }}
            >
              <Typography
                variant="h6"
                align="center"
                sx={{ fontWeight: "bold" }}
              >
                Ofertas
              </Typography>
              <hr></hr>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Referência</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Ano de Fundação</TableCell>
                      <TableCell>Categoria</TableCell>
                      <TableCell>Província</TableCell>
                      <TableCell>Distrito</TableCell>
                      <TableCell>Tipo de Empresa</TableCell>
                      <TableCell>Número de Funcionários</TableCell>
                      <TableCell>Tem Conta</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredOffers.length > 0 ? (
                      filteredOffers.map((offer) => (
                        <TableRow key={offer.id}>
                          <TableCell>{offer.id}</TableCell>
                          <TableCell>{offer.candidateReference}</TableCell>
                          <TableCell>{offer.candidateName}</TableCell>
                          <TableCell>{offer.companyFoundationYear}</TableCell>
                          <TableCell>{offer.category}</TableCell>
                          <TableCell>{offer.province}</TableCell>
                          <TableCell>{offer.district}</TableCell>
                          <TableCell>{offer.companyType}</TableCell>
                          <TableCell>{offer.numberOfEmployees}</TableCell>
                          <TableCell>
                            {offer.haveAcount ? "Sim" : "Não"}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={10} align="center">
                          Nenhuma oferta encontrada.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={12} md={6} sx={{ paddingLeft: 2 }}>
              <Typography
                variant="h6"
                align="center"
                sx={{ fontWeight: "bold" }}
              >
                Demandas
              </Typography>
              <hr></hr>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Referência</TableCell>
                      <TableCell>Responsável</TableCell>
                      <TableCell>Descrição</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Setor de Atividade</TableCell>
                      <TableCell>Sub-setor de Atividade</TableCell>
                      <TableCell>Província</TableCell>
                      <TableCell>Distrito</TableCell>
                      <TableCell>Localização</TableCell>
                      <TableCell>Fase de Execução</TableCell>
                      <TableCell>Duração Perspectiva</TableCell>
                      <TableCell>Data de Publicação</TableCell>
                      <TableCell>Data de Início</TableCell>
                      <TableCell>Data de Término</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredDemands.length > 0 ? (
                      filteredDemands.map((demand) => (
                        <TableRow key={demand.id}>
                          <TableCell>{demand.id}</TableCell>
                          <TableCell>{demand.reference}</TableCell>
                          <TableCell>{demand.responsable}</TableCell>
                          <TableCell>{demand.description}</TableCell>
                          <TableCell>{demand.name}</TableCell>
                          <TableCell>{demand.subActivitySector}</TableCell>
                          <TableCell>{demand.province}</TableCell>
                          <TableCell>{demand.district}</TableCell>
                          <TableCell>{demand.location}</TableCell>
                          <TableCell>{demand.executionPhase}</TableCell>
                          <TableCell>{demand.perpectiveDuration}</TableCell>
                          <TableCell>{demand.pubDate}</TableCell>
                          <TableCell>{demand.startDate}</TableCell>
                          <TableCell>{demand.endDate}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={15} align="center">
                          Nenhuma demanda encontrada.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default MatchmakingTool;
