import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Paper as MuiPaper,
  Button,
} from "@mui/material";
import { GridColDef, GridActionsCellItem, GridRowId } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import OlogaDataTableItems from "../../components/tables/OlogaDataTableItems";
import http from "../../utils/axios";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EmptyCard() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          No Data Available
        </Typography>
        <Typography variant="body2" gutterBottom>
          No Data Available
        </Typography>
      </CardContent>
    </Card>
  );
}

function SalesDetail() {
  const [items, setItems] = useState([]);
  const { state } = useLocation();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Number",
      flex: 1,
      editable: false,
    },
    {
      field: "productName",
      headerName: "Product Name",
      flex: 1,
      editable: false,
    },
    {
      field: "productCategory",
      headerName: "Category",
      flex: 1,
      editable: false,
    },
    {
      field: "brand",
      headerName: "Brand",
      flex: 1,
      editable: false,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      editable: false,
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      flex: 1,
      editable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      editable: false,
    },
  ];

  const getData = async () => {
    try {
      const response = await http.get(`/sales/items/${state.transactionCode}`);
      setItems(response.data.items);
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);
  function EventDetailInfo() {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Event :
          </Typography>
          <Typography variant="body2" gutterBottom>
            Merchant Name:
          </Typography>
          <Typography variant="body2" gutterBottom>
            Merchant Username:
          </Typography>
        </CardContent>
      </Card>
    );
  }
  return (
    <React.Fragment>
      <OlogaPageHeader title="Sales" />

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <Paper>
              <OlogaDataTableItems
                url={`/sales/items/${state.transactionCode}`}
                columns={columns}
              />
            </Paper>
          </Card>
        </Grid>
        <Grid
          container
          marginTop={5}
          spacing={3}
          mt={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Grid item marginRight={2}>
            <Button
              onClick={() => {
                navigate(state.returnUrl, {
                  state: {
                    eventId: state.eventId,
                    scenario: state.scenario,
                  },
                });
              }}
              name="Detail"
              variant="outlined"
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SalesDetail;
