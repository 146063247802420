import React, { useEffect, useState } from "react";
import http from "../../utils/axios";
import { Button, Box, Divider, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormCandidateIcon } from "../../components/Icons";
import useAuth from "../../hooks/useAuth";

const CandidateFeaturesPage: React.FC = () => {
  const navigate = useNavigate();
  const [candidateId, setCandidateId] = useState(0);
  const { user } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await http.get("/users/" + user?.id);
        setCandidateId(result?.data?.candidateId);
      } catch (error) {}
    };
    fetchData();
  }, []);

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Divider>CAPTAÇÃO DE BENEFICIÁRIOS E DEMANDA</Divider>
        <br />
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (candidateId) {
                  navigate(`/candidates/info-Update`, {
                    state: { id: 0 },
                  });
                } else {
                  navigate("/candidates/info-create");
                }
              }}
              startIcon={<FormCandidateIcon />}
            >
              Questionário de Recolha de Informação do Candidato
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CandidateFeaturesPage;
