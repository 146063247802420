import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../../utils/axios";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { AutoFixNormal } from "@mui/icons-material";

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const paperStyle = {
  position: "absolute",
  maxWidth: "70%",
  backgroundColor: "background.paper",
  border: "2px solid #bb86fc",
  padding: 4,
  overflow: "hidden",
};

type Transaction = {
  eventId: String;
  transactionCode: string;
  transactionDate: string;
  totalAmount: number;
};

const TransactionFixerModal: React.FC<{
  open: boolean;
  onClose: any;
  txMetadata: Transaction;
}> = ({ open, onClose, txMetadata }) => {
  const navigate = useNavigate();
  const [eventProducts, setEventProducts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [transactionId, setTransactionId] = useState();

  useEffect(() => {
    open && getDetailedStockList(txMetadata);
  }, [open, txMetadata]);

  const getDetailedStockList = async (txMetadata: Transaction) => {
    const response = await axios.get(
      `/transactions/${
        txMetadata.eventId
      }/${txMetadata.transactionDate.toString()}/${txMetadata.totalAmount}`
    );
    response &&
      setEventProducts(
        response.data.map((r: any) => {
          return {
            id: r.id,
            transactionDate: r.transactionDate,
            totalAmount: r.totalAmount,
          };
        })
      );
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `/transactions/fix/${transactionId}/${txMetadata.transactionCode}`
      );
      setOpenDialog(false);
      handleClose();

      if (response.data.totalUpdated >= 1) {
        toast.success("Process executed sucessfully");
      } else if ((response.data.totalUpdated = 0)) {
        toast.success("Transaction was not updated");
      }
    } catch (error) {
      toast.error((error as any).message);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose} sx={modalStyle}>
      <Box sx={paperStyle}>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>
            By clicking yes, the pagamio transaction you just selected will be
            associated with the selected bank transaction, do you want to
            proceed?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              No
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <IconButton onClick={handleClose} style={{ float: "right" }}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h4">
              Related pending transactions on Pagamio
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/* Table */}
            <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Transaction ID</TableCell>
                    <TableCell>Transaction Date</TableCell>
                    <TableCell>Transaction Amount</TableCell>
                    <TableCell>Associate do Bank Transaction</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventProducts.map(({ id, transactionDate, totalAmount }) => (
                    <TableRow key={id}>
                      <TableCell>{id}</TableCell>
                      <TableCell>{transactionDate}</TableCell>
                      <TableCell> {totalAmount}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            setOpenDialog(true);
                            setTransactionId(id);
                          }}
                          value="Detail"
                        >
                          {<AutoFixNormal />}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
export default TransactionFixerModal;
