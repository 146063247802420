import React, { useEffect, useState } from "react";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Card as MuiCard, Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaDataTableNormal from "../../components/tables/OlogaDataTableNormal";
import { toast } from "react-toastify";
import { Formik } from "formik";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import SearchIcon from "@mui/icons-material/Search";
import axios from "../../utils/axios";
import RefreshIcon from "@mui/icons-material/Refresh";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";

const CandidateProgress: React.FC = () => {
  const Card = styled(MuiCard)(spacing);
  const navigate = useNavigate();
  const [url, setUrl] = useState("/candidate/levels/q");

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Nome Completo",
      flex: 1,
      editable: false,
    },
    {
      field: "nuit",
      headerName: "NUIT",
      flex: 1,
      editable: false,
    },
    {
      field: "levelStatus",
      headerName: "Estado de Nivel",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Acções",
      flex: 1,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<OlogaButton variant="outlined" label="Passar de Nivel" />}
          onClick={async () => {
            const response = await axios.post(
              "candidate/levels/upgrade",
              params.id
            );
            if (response.status === 200) {
              if (response.data === "") {
                toast.error(
                  `There is no Plano de Ação for Candidate ${params?.row?.fullName}`
                );
              } else {
                toast.success(
                  `Candidate ${params?.row?.fullName} Up to ${response.data?.level.code}`
                );
                navigate(`/candidates/progress`);
              }
            } else {
              toast.error("Something went wrong");
            }
          }}
          label="Passar de Nivel"
        />,
        <GridActionsCellItem
          icon={<OlogaButton variant="outlined" label="Baixar de Nivel" />}
          onClick={async () => {
            const response = await axios.post(
              "candidate/levels/downgrade",
              params.id
            );
            if (response.status === 200) {
              if (response.data === "") {
                toast.error(
                  `There is no Plano de Ação for Candidate ${params?.row?.fullName}`
                );
              } else {
                toast.success(
                  `Candidate ${params?.row?.fullName} Down to ${response.data?.level.code}`
                );
                navigate(`/candidates/progress`);
              }
            } else {
              toast.error("Something went wrong");
            }
          }}
          label="Baixar de Nivel"
        />,
      ],
    },
  ];

  const handleSubmit = async (values: any) => {
    try {
      let q = "";
      for (let key in values) {
        if (values.hasOwnProperty(key)) {
          let value = values[key];
          if (value) {
            q += key + "=" + value + "&";
          }
        }
      }
      setUrl(q.length > 0 ? "/candidate/q?" + q.slice(0, -1) : "/candidate/q?");
    } catch (error: any) {
      toast.error(error.message || "Error submitting form");
    }
  };

  useEffect(() => {}, [url]);

  return (
    <React.Fragment>
      <OlogaPageHeader title={"Qualificação e Seleção"}></OlogaPageHeader>
      <Grid container>
        <Grid item xs={12}>
          {/* <Formik initialValues={{}} enableReinitialize onSubmit={handleSubmit}>
            {({ handleSubmit, status }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4} mb={3}>
                  <Grid item xs={12} md={4}>
                    <OlogaTextfield name="firstName" label="First Name" />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <OlogaTextfield name="lastName" label="Last Name" />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <OlogaButton
                      label="Search"
                      type="submit"
                      icon={<SearchIcon />}
                      mt={3}
                      size="large"
                    />
                  </Grid> */}
          <Grid item xs={12} md={6}>
            <OlogaButton
              label="Subir Nivel Automaticamente para todos"
              type="button"
              icon={<RefreshIcon />}
              mt={3}
              size="large"
              onClick={async () => {
                const response = await axios.get("candidate/autoUpgradeLevel");
                if (response.status === 200) {
                  toast.success("All candidates Levels updated");
                  navigate(`/candidates/progress`);
                } else {
                  toast.error("Something went wrong");
                }
              }}
            />
          </Grid>
          {/* </Grid>
              </form>
            )}
          </Formik> */}
          <Card>
            <OlogaDataGrid url={url} columns={columns} />
          </Card>
          <OlogaBackButton />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CandidateProgress;
