import React, { useState, useEffect } from "react";
import { StepIconProps } from "@mui/material/StepIcon";
import { useNavigate } from "react-router-dom";
import {
  Stepper,
  Step,
  StepLabel,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardContent,
  Card,
  FormHelperText,
  LinearProgress,
  Radio,
} from "@mui/material";
import { Remove } from "@mui/icons-material";
import http from "../../utils/axios";
import { toast } from "react-toastify";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import BackButton from "../../components/Buttons/BackButton";
import Terms from "./Terms";
interface Shareholder {
  id: number;
  name: string;
  participation: string;
  nationality: string;
}

interface dropdownDTO {
  text: string;
  value: number;
}

interface Loan {
  id: number;
  entity: string; // Entidade
  loanPurpose: string; // Finalidade do Empréstimo
  amount: string; // Montante
}
interface Certification {
  id: number;
  certificationName: string; // Nome da Certificação
  productDescription: string; // Descrição do Produto/Serviço Certificado (Se Aplicável)
  certificationType: string; // Tipo de Certificação
}

interface Executive {
  id: number;
  name: string;
  position: string;
  industryExperienceYears: string;
  industryExperienceDescription: string;
}

interface CandidateManagement {
  id: number;
  hasRegionalRepresentation: string;
  regionalRepresentationDetails: string;
  plansToExpandGeographies: string;
  expansionDetails: string;
  providesServicesOutsideRegions: string;
  serviceOutsideRegionsDetails: string;
  hasHSEProcesses: string;
  hseProcessesDetails: string;
  performsMaintenance: string;
  maintenanceDetails: string;
  hasQualityControlUnit: string;
  qualityControlUnitDetails: string;
  hasTechnicalCompetence: string;
  technicalCompetenceDetails: string;
  hasEthicsCode: string;
  ethicsCodeProof: string;
  ethicsCodeJustification: string;
  hasOrganizationalStructure: string;
  organizationalStructureProof: string;
  organizationalStructureJustification: string;
  hasDefinedProcesses: string;
  definedProcessesJustification: string;
  hasPurchasePlanning: string;
  certificateProductOrService: string;
  loanExternal: string;
  purchasePlanningJustification: string;
  hasStructuredFunctions: string;
  structuredFunctionsJustification: string;
  hasAdequateResources: string;
  adequateResourcesJustification: string;
  hasGenderEquality: string;
  genderEqualityJustification: string;
  hasHighTurnover: string;
  highTurnoverJustification: string;
  taxRegime: string;
  latestFinancialReportDate: string;
  annualTurnover: string;
  financialStatementsAudited: string;
  auditFrequency: string;
  financialStatementsFrequency: string;
  financialStatementsLast3Years: string;
  hasAdequateEquipment: string;
  adequateEquipmentJustification: string;
  hasAdequateSystems: string;
  adequateSystemsJustification: string;
  hasAdequateInfrastructure: string;
  infrastructureImages: string;
  adequateInfrastructureJustification: string;
}

interface Candidate {
  id: number;
  candidateReference: string;
  candidateName: string;
  candidatureStatus: string;
  firstName: string;
  surname: string;
  companyFoundationYear: string;
  personName: string;
  personPrimaryContact: string;
  personAlternateContact: string;
  fax: string;
  primaryEmail: string;
  alternateEmail: string;
  companyWebsite: string;
  province: string;
  district: string;
  physicalAddress: string;
  category: string;
  activitySector: string;
  subActivitySector: string;
  numberOfEmployees: string;
  size: string;
  activitiesDescription: string;
  marketExperienceYears: string;
  companyType: string;
  employeeProportion: string;
  shareholders: Shareholder[];
  executiveTeam: Executive[];
  desiredAssistance: string;
  additionalAssistance?: string;
  assistanceDetails: string;
  assistancePurpose: string;
  requestedInvestmentAmount?: number;
  createsNewJobs: boolean;
  estimatedAnnualJobCreation?: number;
  previouslyBenefited: boolean;
  previousAssistanceDetails?: string;
  additionalAspects?: string;
  hasCommercialCertificate: string;
  hasINSSCertificate: string;
  hasResidenceProof: string;
  hasID: string;
  hasTaxNumber: string;
  hasTaxNumberCompany: string;
  alvara: string;
  incomeStatement: string;
  hasCourtClearanceCertificate: string;
  hasFinanceClearanceCertificate: string;
  hasCriminalRecord: string;
  noDocumentJustification: string;

  candidateManagement: CandidateManagement;
}

interface FormTypeErrors {
  desiredAssistance: boolean;
}

interface Errors {
  province: boolean;
  category: boolean;
  primaryEmail: boolean;
}

interface PersonErrors {
  hasID: boolean;
  hasTaxNumber: boolean;
}

interface CampanyErrors {
  hasTaxNumber: boolean;
  hasTaxNumberCompany: boolean;
  hasID: boolean;
}

interface CandidateFormProps {
  id: number;
  userType: string;
}

const CandidateForm: React.FC<CandidateFormProps> = ({ id, userType }) => {
  const [steps, setSteps] = useState([
    "Instruções de Preenchimento",
    "Assistência Requerida",
  ]);

  const [formtype, setFormType] = useState(5);
  const [finalBtn, SetFinalBtn] = useState(false);
  const [showCompanyInfo, setShowCompanyInfo] = useState(true);
  const [selectedRequirement, setSelectedRequirement] = useState("empresas");
  const handleRequirementChange = (event: any) => {
    setSelectedRequirement(event.target.value);
  };
  const navigate = useNavigate();
  const [candidatureStatus, setCandidatureStatus] = useState("");
  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const [dropdown_quarter_year, setDropdown_quarter_year] = useState<
    dropdownDTO[]
  >([]);

  const [
    dropdown_estimatedAnnualJobCreation,
    setDropdown_estimatedAnnualJobCreation,
  ] = useState<dropdownDTO[]>([]);
  const [dropdown_yes_or_no, setDropdown_yes_or_no] = useState<dropdownDTO[]>(
    []
  );
  const [dropdown_desiredAssistance, setDropdown_desiredAssistance] = useState<
    dropdownDTO[]
  >([]);
  const [dropdown_companyType, setDropdown_companyType] = useState<
    dropdownDTO[]
  >([]);
  const [dropdown_marketExperienceYears, setDropdown_marketExperienceYears] =
    useState<dropdownDTO[]>([]);
  const [dropdown_size, setDropdown_size] = useState<dropdownDTO[]>([]);
  const [dropdown_category, setDropdown_category] = useState<dropdownDTO[]>([]);
  const [dropdown_activitySector, setDropdown_activitySector] = useState<
    dropdownDTO[]
  >([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [candidate, setCandidate] = useState<Candidate>({
    id: 0,
    candidateReference: "",
    candidateName: "",
    firstName: "",
    surname: "",
    companyFoundationYear: "",
    personName: "",
    personPrimaryContact: "",
    personAlternateContact: "",
    fax: "",
    primaryEmail: "",
    alternateEmail: "",
    companyWebsite: "",
    province: "",
    district: "",
    physicalAddress: "",
    category: "",
    activitySector: "",
    subActivitySector: "",
    numberOfEmployees: "",
    size: "",
    activitiesDescription: "",
    marketExperienceYears: "",
    companyType: "",
    employeeProportion: "",
    shareholders: [],
    executiveTeam: [],
    candidatureStatus: "",
    desiredAssistance: "",
    additionalAssistance: "",
    assistanceDetails: "",
    assistancePurpose: "",
    requestedInvestmentAmount: 0,
    createsNewJobs: false,
    estimatedAnnualJobCreation: 0,
    previouslyBenefited: false,
    previousAssistanceDetails: "",
    additionalAspects: "",
    hasCommercialCertificate: "",
    hasINSSCertificate: "",
    hasID: "",
    hasResidenceProof: "",
    hasTaxNumber: "",
    hasTaxNumberCompany: "",
    alvara: "",
    incomeStatement: "",
    hasCourtClearanceCertificate: "",
    hasFinanceClearanceCertificate: "",
    hasCriminalRecord: "",
    noDocumentJustification: "",

    candidateManagement: {
      id: 0,
      certificateProductOrService: "",
      financialStatementsLast3Years: "",
      hasRegionalRepresentation: "",
      loanExternal: "",
      regionalRepresentationDetails: "",
      plansToExpandGeographies: "",
      expansionDetails: "",
      providesServicesOutsideRegions: "",
      serviceOutsideRegionsDetails: "",
      hasHSEProcesses: "",
      hseProcessesDetails: "",
      performsMaintenance: "",
      maintenanceDetails: "",
      hasQualityControlUnit: "",
      qualityControlUnitDetails: "",
      hasTechnicalCompetence: "",
      technicalCompetenceDetails: "",
      hasEthicsCode: "",
      ethicsCodeProof: "",
      ethicsCodeJustification: "",
      hasOrganizationalStructure: "",
      organizationalStructureProof: "",
      organizationalStructureJustification: "",
      hasDefinedProcesses: "",
      definedProcessesJustification: "",
      hasPurchasePlanning: "",
      purchasePlanningJustification: "",
      hasStructuredFunctions: "",
      structuredFunctionsJustification: "",
      hasAdequateResources: "",
      adequateResourcesJustification: "",
      hasGenderEquality: "",
      genderEqualityJustification: "",
      hasHighTurnover: "",
      highTurnoverJustification: "",
      taxRegime: "",
      latestFinancialReportDate: "",
      annualTurnover: "",
      financialStatementsAudited: "",
      auditFrequency: "",
      financialStatementsFrequency: "",
      hasAdequateEquipment: "",
      adequateEquipmentJustification: "",
      hasAdequateSystems: "",
      adequateSystemsJustification: "",
      hasAdequateInfrastructure: "",
      infrastructureImages: "",
      adequateInfrastructureJustification: "",
    },
  });
  const [errors, setErrors] = useState<Errors>({
    province: false,
    primaryEmail: false,
    category: false,
  });
  const [formTypeErrors, setFormTypeErrors] = useState<FormTypeErrors>({
    desiredAssistance: false,
  });
  const [errorsPerson, setErrorsPerson] = useState<PersonErrors>({
    hasID: false,
    hasTaxNumber: false,
  });
  const [errorsCampany, setErrorsCampany] = useState<CampanyErrors>({
    hasTaxNumberCompany: false,
    hasTaxNumber: false,
    hasID: false,
  });
  function getStepsByAssistanceType(assistanceType: string) {
    let steps = ["Instruções de Preenchimento", "Assistência Requerida"];
    switch (assistanceType) {
      case "Suporte de registro na Plataforma de Fornecedores da MZLNG":
      case "Suporte à Formalização de Empresas":
        steps.push("Informações Gerais do Candidato");
        setFormType(2);
        break;

      case "Coaching e Mentoria":
      case "Suporte ao Acesso ao Financiamento":
      case "Suporte ao Desenvolvimento de Projectos":
      case "Suporte Administrativo do Negócio":
        steps.push("Informações Gerais do Candidato", "Perfil da Empresa");
        setFormType(3);
        break;

      case "Formação e Capacity-Building TEPMA 1":
      case "Acesso ao Investimento":
        steps.push(
          "Informações Gerais do Candidato",
          "Perfil da Empresa",
          "Informação Legal",
          "Informação sobre a gestão da empresa do candidato"
        );
        setFormType(5);
        break;

      default:
        console.warn("Assistência desconhecida selecionada.");
        setFormType(5);
        break;
    }

    setSteps(steps);
  }
  function getStepsByCategory(category: string, assistanceType: string) {
    let steps = ["Instruções de Preenchimento", "Assistência Requerida"];

    switch (assistanceType) {
      case "Suporte de registro na Plataforma de Fornecedores da MZLNG":
      case "Suporte à Formalização de Empresas":
        steps.push("Informações Gerais do Candidato");
        setFormType(2);
        break;

      case "Coaching e Mentoria":
      case "Suporte ao Acesso ao Financiamento":
      case "Suporte ao Desenvolvimento de Projectos":
      case "Suporte Administrativo do Negócio":
        steps.push("Informações Gerais do Candidato", "Perfil da Empresa");
        setFormType(3);
        break;

      case "Formação e Capacity-Building TEPMA 1":
      case "Acesso ao Investimento":
        steps.push(
          "Informações Gerais do Candidato",
          "Perfil da Empresa",
          "Informação Legal",
          "Informação sobre a gestão da empresa do candidato"
        );
        setFormType(5);
        break;

      default:
        console.warn("Assistência desconhecida selecionada.");
        setFormType(5);
        break;
    }

    if (category === "Particular") {
      setSelectedRequirement("particulares");
    } else {
      setSelectedRequirement("empresas");
    }
    // Se a categoria for "Particular", remove o passo "Perfil da Empresa"
    if (category === "Particular") {
      if (formtype === 3) {
        SetFinalBtn(true);
        var stypeNumbers = formtype - 1;
        setFormType(stypeNumbers);
      } else {
        setShowCompanyInfo(false);
      }
    }

    setSteps(steps);
  }
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await http.get(`simple-entity/findDropdown/PROVINCE`);
        setDropdown_province(response.data);

        const CANDIDATE_CATEGORY = await http.get(
          `simple-entity/findDropdown/CANDIDATE_CATEGORY`
        );
        setDropdown_category(CANDIDATE_CATEGORY.data);

        const ACTIVITY_SECTOR = await http.get(
          `simple-entity/findDropdown/ACTIVITY_SECTOR`
        );
        setDropdown_activitySector(ACTIVITY_SECTOR.data);

        const CANDIDATE_SIZE = await http.get(
          `simple-entity/findDropdown/CANDIDATE_SIZE`
        );
        setDropdown_size(CANDIDATE_SIZE.data);

        const MARKET_TIME = await http.get(
          `simple-entity/findDropdown/MARKET_TIME`
        );
        setDropdown_marketExperienceYears(MARKET_TIME.data);

        const TYPE_OF_COMPANY = await http.get(
          `simple-entity/findDropdown/TYPE_OF_COMPANY`
        );
        setDropdown_companyType(TYPE_OF_COMPANY.data);

        const SUPPORT_TYPE = await http.get(
          `simple-entity/findDropdown/SUPPORT_TYPE`
        );
        setDropdown_desiredAssistance(SUPPORT_TYPE.data);

        const CREATION_OF_NEW_JOB = await http.get(
          `simple-entity/findDropdown/CREATION_OF_NEW_JOB`
        );
        setDropdown_estimatedAnnualJobCreation(CREATION_OF_NEW_JOB.data);

        const YE_OR_NO = await http.get(`simple-entity/findDropdown/YE_OR_NO`);
        setDropdown_yes_or_no(YE_OR_NO.data);

        const QUARTER_YEAR = await http.get(
          `simple-entity/findDropdown/QUARTER_YEAR`
        );
        setDropdown_quarter_year(QUARTER_YEAR.data);
      } catch (error) {
        console.error("Error fetching options", error);
      } finally {
      }
    };
    fetchOptions();
    if (id > 0) {
      const fetchCandidateInfo = async () => {
        const result = await http.get(`/candidate/` + id);
        setCandidate(result.data);
        setCandidatureStatus(result.data?.candidatureStatus);
        getStepsByAssistanceType(result.data?.desiredAssistance);
      };
      fetchCandidateInfo();
    }
  }, []);

  const handleStepChange = (step: number, type: string) => {
    console.log("step", step);
    if (step == 2) {
      const newErrors: FormTypeErrors = {
        desiredAssistance: candidate.desiredAssistance === "",
      };

      /*   const totalSteps = getStepsByAssistanceType(candidate.desiredAssistance);
      setSteps(totalSteps); */

      if (Object.values(newErrors).some((error) => error)) {
        setFormTypeErrors(newErrors);
        return;
      }
    }
    if (step == 3) {
      const newErrors: Errors = {
        province: candidate.province === "",
        primaryEmail: candidate.primaryEmail === "",
        category: candidate.category === "",
      };

      if (Object.values(newErrors).some((error) => error)) {
        setErrors(newErrors);
        return;
      }
    }
    if (step == 5) {
      if (selectedRequirement === "particulares") {
        const newErrors: PersonErrors = {
          hasID: candidate.hasID === "",
          hasTaxNumber: candidate.hasTaxNumber === "",
        };

        if (Object.values(newErrors).some((error) => error)) {
          setErrorsPerson(newErrors);
          return;
        }
      }

      if (selectedRequirement === "empresas") {
        const newErrors: CampanyErrors = {
          hasTaxNumber: candidate.hasTaxNumber === "",
          hasTaxNumberCompany: candidate.hasTaxNumberCompany === "",
          hasID: candidate.hasID === "",
        };

        if (Object.values(newErrors).some((error) => error)) {
          setErrorsCampany(newErrors);
          return;
        }
      }
    }
    if (step == 3) {
      if (!showCompanyInfo) {
        if (type == "next") {
          step++;
        } else {
          step--;
        }
      }
    }
    setActiveStep(step);
  };

  const handleChange = (field: keyof Candidate, value: string | boolean) => {
    setCandidate((prevState) => ({ ...prevState, [field]: value }));
    setErrors({
      ...errors,
      [field]: value === "",
    });
  };
  const CustomStepIcon = (props: StepIconProps) => {
    const { active, completed, icon } = props;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 25,
          height: 25,
          borderRadius: "50%",
          backgroundColor: active || completed ? "#007680" : "#697b7c",
          color: "#fff",
          fontWeight: "bold",
        }}
      >
        {icon}
      </div>
    );
  };
  const handleArrayChange = (
    index: number,
    field: string,
    value: string,
    arrayName: "shareholders" | "executiveTeam"
  ) => {
    const updatedArray = [...candidate[arrayName]];
    updatedArray[index] = { ...updatedArray[index], [field]: value };
    setCandidate((prevState) => ({ ...prevState, [arrayName]: updatedArray }));
  };

  const handleAddToArray = (arrayName: "shareholders" | "executiveTeam") => {
    setCandidate((prevState) => {
      if (prevState[arrayName].length >= 5) {
        return prevState;
      }

      const newItem =
        arrayName === "shareholders"
          ? { name: "", shares: "" }
          : arrayName === "executiveTeam"
          ? { name: "", position: "" }
          : arrayName === "certifications"
          ? {
              certificationName: "",
              certificationDate: "",
              certificationIssuer: "",
            }
          : { loanAmount: "", loanTerm: "", interestRate: "" };

      return {
        ...prevState,
        [arrayName]: [...prevState[arrayName], newItem],
      };
    });
  };

  const handleRemoveFromArray = (
    index: number,
    arrayName: "shareholders" | "executiveTeam"
  ) => {
    const array = candidate[arrayName];
    if (arrayName === "shareholders") {
      const updatedArray = (array as Shareholder[]).filter(
        (_, i: number) => i !== index
      );
      setCandidate((prevState) => ({
        ...prevState,
        shareholders: updatedArray,
      }));
    } else if (arrayName === "executiveTeam") {
      const updatedArray = (array as Executive[]).filter(
        (_, i: number) => i !== index
      );
      setCandidate((prevState) => ({
        ...prevState,
        executiveTeam: updatedArray,
      }));
    }
  };
  const handleSubmit = async () => {
    try {
      const result = await http.post(`/candidate`, candidate);
      toast.success(`Informações Gerais do Candidato Criadas com sucesso!`);

      if (userType === "" || userType === "ROLE_CANDIDATE") {
        navigate(-1);
      } else {
        navigate("/assessment-admin/search");
      }
    } catch (error: any) {
      console.log("error:", error);
      toast.error(
        error.message || "Erro ao Criar Informações Gerais do Candidato"
      );
    }
  };

  const handleSelectChange = (fieldName: any) => (event: any) => {
    const { value } = event.target;
    setCandidate((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));

    if (fieldName == "desiredAssistance") {
      getStepsByAssistanceType(value);
    }

    if (fieldName == "category") {
      getStepsByCategory(value, candidate.desiredAssistance);
    }
  };
  const progress = (activeStep / (steps.length - 1)) * 100;

  return (
    <>
      <Card sx={{ margin: "auto" }}>
        <CardContent>
          <Box>
            <OlogaPageHeader
              title={"Formulário de recolha de informação"}
            ></OlogaPageHeader>
            <center>
              <Typography
                variant="caption"
                align="center"
                style={{ marginBottom: "8px" }}
              >
                Progresso: {Math.round(progress)}%
              </Typography>
            </center>
            <LinearProgress
              variant="determinate"
              value={progress}
              style={{ marginBottom: "16px" }}
            />
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={CustomStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            <div>
              {activeStep === 0 &&
                (userType === "" || userType === "ROLE_CANDIDATE" ? (
                  <>
                    <Terms />
                    <Card variant="elevation" sx={{ mb: 3 }}>
                      <CardContent>
                        <Typography
                          variant="h4"
                          gutterBottom
                          sx={{ fontSize: "1.5rem" }}
                        >
                          Questionário de Recolha de Informação dos Candidatos
                          do One Stop Shop
                        </Typography>
                        <Typography
                          variant="body1"
                          paragraph
                          sx={{ fontSize: "1.2rem" }}
                        >
                          Caro Candidato,
                        </Typography>
                        <Typography
                          variant="body1"
                          paragraph
                          sx={{ fontSize: "1.2rem" }}
                        >
                          O questionário deve ser preenchido de forma honesta e
                          completa, pois isso permitirá a correta identificação
                          das necessidades do Candidato.
                        </Typography>
                      </CardContent>
                    </Card>
                  </>
                ) : (
                  <Card variant="elevation">
                    <CardContent>
                      <Typography
                        variant="h4"
                        gutterBottom
                        sx={{ fontSize: "1.5rem" }}
                      >
                        Instruções de Preenchimento
                      </Typography>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ fontSize: "1.2rem" }}
                      >
                        Por forma a garantir o preenchimento assertivo das
                        ferramentas identificadas nas tabs seguintes,
                        dever-se-ão observar os seguintes passos:
                      </Typography>
                      <ul>
                        <li>
                          <Typography>
                            <strong>1.</strong> Primeiramente, deve-se proceder
                            com o preenchimento da Ferramenta T1 (Questionário
                            de Recolha de Informação dos Beneficiários), tendo
                            em conta o tipo de assistência requerida pelo
                            Beneficiário, conforme instruções de preenchimento
                            descritas na respectiva ferramenta.
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>2.</strong> De seguida e após recolha de
                            informação do Beneficiário no ponto anterior,
                            dever-se-á transferir os dados de cada questionário
                            preenchido por cada Beneficiário para a ferramenta
                            T16 (Base de Dados Master), onde serão armazenados
                            os dados de todos os Beneficiários. Esta ferramenta
                            será constantemente actualizada, com base no estágio
                            de avaliação e de prestação do serviço a cada um dos
                            Beneficiários.
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>3.</strong> Uma vez preenchida a Base de
                            dados Master dos Beneficiários, deve ser igualmente
                            preenchida a T2.1 (Ferramenta de Mapeamento da
                            Oferta), onde são mapeados todos os Beneficiários,
                            com a descrição da sua atividade para efeitos de
                            realização do Matchmaking.
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>4.</strong> Posteriormente e sempre que haja
                            uma demanda/oportunidade da TEPMA1, do OSS ou ainda
                            de outras entidades, deverão ser mapeadas na
                            ferramenta T2.2 (Ferramenta de Mapeamento da
                            Demanda).
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>5.</strong> Após a recolha da informação e
                            identificação do tipo de assistência requerida pelos
                            Beneficiários, segue o processo de selecção e
                            priorização dos Beneficiários. Dependendo do Nível
                            de Serviço em que o Beneficiário se encontra,
                            deverão ser preenchidas as seguintes ferramentas:
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>Nível 1:</strong> Beneficiários sem qualquer
                            processo de validação e priorização. Estes serão
                            diretamente mapeados na Ferramenta T7 (Ferramenta de
                            Listagem dos Beneficiários Pré-Seleccionados) como
                            sendo Beneficiários elegíveis para receber a(s)
                            assistência(s) requerida(s).
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>Nível 2:</strong> Beneficiários alvo de
                            preenchimento da Ferramenta T5 (Ferramenta de
                            Priorização dos Beneficiários). Uma vez priorizados
                            (Beneficiários com Classificação Global Elevada), os
                            Beneficiários serão igualmente listados na
                            Ferramenta T7 (Ferramenta de Listagem dos
                            Beneficiários Pré-Seleccionados), que constitui a
                            lista final dos Beneficiários Seleccionados.
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>Nível 3:</strong> Beneficiários alvo de
                            preenchimento das Ferramentas abaixo, atendendo à
                            seguinte sequência:
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>I.</strong> T3 (Ferramenta de Pré-Selecção
                            dos Beneficiários), de acordo com a demanda por
                            projetos existentes por parte da TEPMA1. Somente os
                            Beneficiários com a Classificação Global
                            'Seleccionado', transitam para a fase seguinte de
                            avaliação.
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>II.</strong> Seguido do preenchimento da
                            T4.1 (Ferramenta de Avaliação Legal e de Compliance
                            de Empresas) e a T4.2 (Ferramenta de Avaliação Legal
                            e de Compliance de Particulares), voltadas a
                            determinar se as empresas e particulares estão
                            devidamente constituídas e não possuem nenhum
                            cadastro criminal. De referir que só os
                            beneficiários que com base nos critérios
                            estabelecidos forem considerados compliance,
                            passarão para a próxima fase.
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>III.</strong> E finalmente, a T6 (Ferramenta
                            de Avaliação Final dos Beneficiários), onde o
                            Técnico Responsável avalia o perfil final dos
                            Beneficiários para efeitos de decisão final quanto à
                            sua elegibilidade.
                          </Typography>
                        </li>
                      </ul>
                    </CardContent>
                  </Card>
                ))}
              {activeStep === 1 && (
                <Box p={3}>
                  <Typography variant="h6">Assistência Requerida</Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="desiredAssistance-label">
                          Por favor indique a Assistência que deseja obter
                        </InputLabel>
                        <Select
                          labelId="desiredAssistance-label"
                          value={candidate.desiredAssistance}
                          label="Por favor indique a Assistência que deseja obter"
                          onChange={handleSelectChange("desiredAssistance")}
                          required
                          error={formTypeErrors.desiredAssistance}
                        >
                          {dropdown_desiredAssistance.map(
                            (desiredAssistance) => (
                              <MenuItem
                                key={desiredAssistance.text}
                                value={desiredAssistance.text}
                              >
                                {desiredAssistance.text}
                              </MenuItem>
                            )
                          )}
                        </Select>{" "}
                        {formTypeErrors.desiredAssistance && (
                          <FormHelperText error>
                            Campo obrigatório
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Caso deseja obter Assistência(s) adicional(ais), por favor especifique."
                        value={candidate.additionalAssistance}
                        onChange={(e) =>
                          handleChange("additionalAssistance", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Por favor, forneça o detalhe da(s) assistência(s) que deseja obter"
                        value={candidate.assistanceDetails}
                        onChange={(e) =>
                          handleChange("assistanceDetails", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Indique por favor a finalidade da(s) Assistência(s) solicitada(s)*"
                        value={candidate.assistancePurpose}
                        onChange={(e) =>
                          handleChange("assistancePurpose", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Caso tenha seleccionado a opção 'Acesso ao Investimento', por favor indique o montante requerido (opcional)"
                        value={candidate.requestedInvestmentAmount}
                        onChange={(e) =>
                          handleChange(
                            "requestedInvestmentAmount",
                            e.target.value
                          )
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={candidate.createsNewJobs}
                        onChange={(e) =>
                          handleChange("createsNewJobs", e.target.checked)
                        }
                      />
                    }
                    label="A(s) Assistência(s) solicitada(s) permite a criação novos postos de trabalho?"
                  />
                  {candidate.createsNewJobs && (
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="estimatedAnnualJobCreation-label">
                        Se sim, por favor, especifique a estimativa anual de
                        criação destes postos (opcional)
                      </InputLabel>
                      <Select
                        labelId="estimatedAnnualJobCreation-label"
                        value={candidate.estimatedAnnualJobCreation}
                        label="Se sim, por favor, especifique a estimativa anual de
                  criação destes postos (opcional)"
                        onChange={handleSelectChange(
                          "estimatedAnnualJobCreation"
                        )}
                      >
                        {dropdown_estimatedAnnualJobCreation.map(
                          (estimatedAnnualJobCreation) => (
                            <MenuItem
                              key={estimatedAnnualJobCreation.text}
                              value={estimatedAnnualJobCreation.text}
                            >
                              {estimatedAnnualJobCreation.text}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={candidate.previouslyBenefited}
                        onChange={(e) =>
                          handleChange("previouslyBenefited", e.target.checked)
                        }
                      />
                    }
                    label="O Candidato já se beneficiou anteriormente deste tipo de Assistências?"
                  />
                  {candidate.previouslyBenefited && (
                    <TextField
                      label="Se sim, por favor, forneça o detalhe da assistência anteriormente prestada (opcional)"
                      value={candidate.previousAssistanceDetails}
                      onChange={(e) =>
                        handleChange(
                          "previousAssistanceDetails",
                          e.target.value
                        )
                      }
                      fullWidth
                      margin="normal"
                    />
                  )}
                  <TextField
                    label="Por favor, descreva os aspectos adicionais que acredita estar em falta ou que gostaria de obter melhorias na sua empresa (opcional)"
                    value={candidate.additionalAspects}
                    onChange={(e) =>
                      handleChange("additionalAspects", e.target.value)
                    }
                    fullWidth
                    margin="normal"
                  />
                </Box>
              )}
              {activeStep === 2 && (
                <Box p={3}>
                  <Typography variant="h6">
                    Informações Gerais do Candidato
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="category-label">
                          Categoria do Candidato
                        </InputLabel>
                        <Select
                          labelId="category-label"
                          value={candidate.category}
                          label="Categoria do Candidato"
                          onChange={handleSelectChange("category")}
                          required
                          error={errors.category}
                        >
                          {dropdown_category.map((category) => (
                            <MenuItem key={category.text} value={category.text}>
                              {category.text}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.category && (
                          <FormHelperText error>
                            Campo obrigatório
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Nome"
                        value={candidate.firstName}
                        onChange={(e) =>
                          handleChange("firstName", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Sobrenome"
                        value={candidate.surname}
                        onChange={(e) =>
                          handleChange("surname", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Ano de Fundação da Empresa do Candidado (se Aplicável)"
                        type="number"
                        value={candidate.companyFoundationYear}
                        onChange={(e) =>
                          handleChange("companyFoundationYear", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Pessoa de Contacto"
                        value={candidate.personName}
                        onChange={(e) =>
                          handleChange("personName", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Contato Principal"
                        value={candidate.personPrimaryContact}
                        onChange={(e) =>
                          handleChange("personPrimaryContact", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Pessoa de Contacto Alternativo"
                        value={candidate.personAlternateContact}
                        onChange={(e) =>
                          handleChange("personAlternateContact", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Fax/Telefax"
                        value={candidate.fax}
                        onChange={(e) => handleChange("fax", e.target.value)}
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="E-mail Principal"
                        value={candidate.primaryEmail}
                        onChange={(e) =>
                          handleChange("primaryEmail", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                        required
                        error={errors.primaryEmail}
                      />
                      {errors.primaryEmail && (
                        <FormHelperText error>Campo obrigatório</FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Email Alternativo"
                        value={candidate.alternateEmail}
                        onChange={(e) =>
                          handleChange("alternateEmail", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Website da Empresa do Candidato"
                        value={candidate.companyWebsite}
                        onChange={(e) =>
                          handleChange("companyWebsite", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="province-label">Província</InputLabel>
                        <Select
                          labelId="province-label"
                          value={candidate.province}
                          label="Província"
                          onChange={handleSelectChange("province")}
                          required
                          error={errors.province}
                        >
                          {dropdown_province.map((province) => (
                            <MenuItem key={province.text} value={province.text}>
                              {province.text}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.province && (
                          <FormHelperText error>
                            Campo obrigatório
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Distrito"
                        value={candidate.district}
                        onChange={(e) =>
                          handleChange("district", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <TextField
                    label="Endereço Físico"
                    value={candidate.physicalAddress}
                    onChange={(e) =>
                      handleChange("physicalAddress", e.target.value)
                    }
                    fullWidth
                    margin="normal"
                  />
                </Box>
              )}
              {activeStep === 3 && (
                <Box p={3}>
                  <Typography variant="h6">Perfil da Empresa</Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="activitySector-label">
                          Setor de Atividade
                        </InputLabel>
                        <Select
                          labelId="activitySector-label"
                          value={candidate.activitySector}
                          label="Setor de Atividade"
                          onChange={handleSelectChange("activitySector")}
                        >
                          {dropdown_activitySector.map((activitySector) => (
                            <MenuItem
                              key={activitySector.text}
                              value={activitySector.text}
                            >
                              {activitySector.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Subsector de Atividade"
                        value={candidate.subActivitySector}
                        onChange={(e) =>
                          handleChange("subActivitySector", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Número de Funcionários"
                        type="number"
                        value={candidate.numberOfEmployees}
                        onChange={(e) =>
                          handleChange("numberOfEmployees", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="size-label">
                          Dimensão do Candidato
                        </InputLabel>
                        <Select
                          labelId="size-label"
                          value={candidate.size}
                          label="Dimensão do Candidato"
                          onChange={handleSelectChange("size")}
                        >
                          {dropdown_size.map((size) => (
                            <MenuItem key={size.text} value={size.text}>
                              {size.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Breve descrição das actividades da empresa do Candidato"
                        value={candidate.activitiesDescription}
                        onChange={(e) =>
                          handleChange("activitiesDescription", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="marketExperienceYears-label">
                          Antiguidade no Mercado
                        </InputLabel>
                        <Select
                          labelId="marketExperienceYears-label"
                          value={candidate.marketExperienceYears}
                          label="Antiguidade no Mercado"
                          onChange={handleSelectChange("marketExperienceYears")}
                        >
                          {dropdown_marketExperienceYears.map(
                            (marketExperienceYears) => (
                              <MenuItem
                                key={marketExperienceYears.text}
                                value={marketExperienceYears.text}
                              >
                                {marketExperienceYears.text}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="companyType-label">
                          Tipo de Sociedade
                        </InputLabel>
                        <Select
                          labelId="companyType-label"
                          value={candidate.companyType}
                          label="Tipo de Sociedade"
                          onChange={handleSelectChange("companyType")}
                        >
                          {dropdown_companyType.map((companyType) => (
                            <MenuItem
                              key={companyType.text}
                              value={companyType.text}
                            >
                              {companyType.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <label>
                        A Empresa do Candidato possui Accionistas? Se sim, por
                        favor indentifique-os, indique a sua quota de
                        participação e nacionalidade
                      </label>
                    </Grid>
                    <Grid item xs={6}>
                      Por favor, forneça o detalhe abaixo, sobre os membros
                      Equipa Executiva da Empresa do Candidato*
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      {" "}
                      {candidate.shareholders.map((shareholder, index) => (
                        <Box key={index} mb={2}>
                          <TextField
                            label="Nome"
                            value={shareholder.name}
                            onChange={(e) =>
                              handleArrayChange(
                                index,
                                "name",
                                e.target.value,
                                "shareholders"
                              )
                            }
                            fullWidth
                            margin="normal"
                          />
                          <TextField
                            label="Número de Ações"
                            value={shareholder.participation}
                            onChange={(e) =>
                              handleArrayChange(
                                index,
                                "participation",
                                e.target.value,
                                "shareholders"
                              )
                            }
                            fullWidth
                            margin="normal"
                          />
                          <TextField
                            label="Nacionalidade"
                            value={shareholder.nationality}
                            onChange={(e) =>
                              handleArrayChange(
                                index,
                                "nationality",
                                e.target.value,
                                "shareholders"
                              )
                            }
                            fullWidth
                            margin="normal"
                          />

                          <IconButton
                            onClick={() =>
                              handleRemoveFromArray(index, "shareholders")
                            }
                          >
                            <Remove />
                          </IconButton>
                        </Box>
                      ))}
                      <Button
                        variant="contained"
                        onClick={() => handleAddToArray("shareholders")}
                      >
                        Adicionar Acionista
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      {candidate.executiveTeam.map((executive, index) => (
                        <Box key={index} mb={2}>
                          <TextField
                            label="Nome"
                            value={executive.name}
                            onChange={(e) =>
                              handleArrayChange(
                                index,
                                "name",
                                e.target.value,
                                "executiveTeam"
                              )
                            }
                            fullWidth
                            margin="normal"
                          />
                          <TextField
                            label="Função na Empresa"
                            value={executive.position}
                            onChange={(e) =>
                              handleArrayChange(
                                index,
                                "position",
                                e.target.value,
                                "executiveTeam"
                              )
                            }
                            fullWidth
                            margin="normal"
                          />
                          <TextField
                            label="Anos de Experiência na Indústria"
                            value={executive.industryExperienceYears}
                            onChange={(e) =>
                              handleArrayChange(
                                index,
                                "industryExperienceYears",
                                e.target.value,
                                "executiveTeam"
                              )
                            }
                            fullWidth
                            margin="normal"
                          />
                          <TextField
                            label="Descrição da Experiência na Indústria"
                            value={executive.industryExperienceDescription}
                            onChange={(e) =>
                              handleArrayChange(
                                index,
                                "industryExperienceDescription",
                                e.target.value,
                                "executiveTeam"
                              )
                            }
                            fullWidth
                            margin="normal"
                          />
                          <IconButton
                            onClick={() =>
                              handleRemoveFromArray(index, "executiveTeam")
                            }
                          >
                            <Remove />
                          </IconButton>
                        </Box>
                      ))}
                      <Button
                        variant="contained"
                        onClick={() => handleAddToArray("executiveTeam")}
                      >
                        Adicionar Membro da Equipe
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {activeStep === 4 && (
                <Box p={3}>
                  <Typography variant="h6">Informação Legal</Typography>

                  {selectedRequirement === "empresas" && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          Requisitos para Empresas
                        </Typography>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="hasCommercialCertificate-label">
                              Certidão Comercial
                            </InputLabel>
                            <Select
                              labelId="hasCommercialCertificate-label"
                              value={candidate.hasCommercialCertificate}
                              label="Certidão Comercial"
                              onChange={handleSelectChange(
                                "hasCommercialCertificate"
                              )}
                            >
                              {dropdown_yes_or_no.map(
                                (hasCommercialCertificate) => (
                                  <MenuItem
                                    key={hasCommercialCertificate.text}
                                    value={hasCommercialCertificate.text}
                                  >
                                    {hasCommercialCertificate.text}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="hasINSSCertificate-label">
                              Certidão de Quitação do INSS
                            </InputLabel>
                            <Select
                              labelId="hasINSSCertificate-label"
                              value={candidate.hasINSSCertificate}
                              label="Certidão de Quitação do INSS"
                              onChange={handleSelectChange(
                                "hasINSSCertificate"
                              )}
                            >
                              {dropdown_yes_or_no.map((hasINSSCertificate) => (
                                <MenuItem
                                  key={hasINSSCertificate.text}
                                  value={hasINSSCertificate.text}
                                >
                                  {hasINSSCertificate.text}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="hasTaxNumberCompany-label">
                              NUIT Empresarial
                            </InputLabel>
                            <Select
                              labelId="hasTaxNumberCompany-label"
                              value={candidate.hasTaxNumberCompany}
                              label="NUIT"
                              onChange={handleSelectChange(
                                "hasTaxNumberCompany"
                              )}
                              required
                              error={errorsCampany.hasTaxNumberCompany}
                            >
                              {dropdown_yes_or_no.map((hasTaxNumberCompany) => (
                                <MenuItem
                                  key={hasTaxNumberCompany.text}
                                  value={hasTaxNumberCompany.text}
                                >
                                  {hasTaxNumberCompany.text}
                                </MenuItem>
                              ))}
                            </Select>{" "}
                            {errorsCampany.hasTaxNumberCompany && (
                              <FormHelperText error>
                                Campo obrigatório
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="hasCourtClearanceCertificate-label">
                              Certidão de Quitação do Tribunal
                            </InputLabel>
                            <Select
                              labelId="hasCourtClearanceCertificate-label"
                              value={candidate.hasCourtClearanceCertificate}
                              label="Certidão de Quitação do Tribunal"
                              onChange={handleSelectChange(
                                "hasCourtClearanceCertificate"
                              )}
                            >
                              {dropdown_yes_or_no.map(
                                (hasCourtClearanceCertificate) => (
                                  <MenuItem
                                    key={hasCourtClearanceCertificate.text}
                                    value={hasCourtClearanceCertificate.text}
                                  >
                                    {hasCourtClearanceCertificate.text}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="alvara-label">Alvara</InputLabel>
                            <Select
                              labelId="alvara-label"
                              value={candidate.alvara}
                              label="Alvara"
                              onChange={handleSelectChange("alvara")}
                            >
                              {dropdown_yes_or_no.map((alvara) => (
                                <MenuItem key={alvara.text} value={alvara.text}>
                                  {alvara.text}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="hasFinanceClearanceCertificate-label">
                              Certidão de Quitação das Finanças
                            </InputLabel>
                            <Select
                              labelId="hasFinanceClearanceCertificate-label"
                              value={candidate.hasFinanceClearanceCertificate}
                              label="Certidão de Quitação das Finanças"
                              onChange={handleSelectChange(
                                "hasFinanceClearanceCertificate"
                              )}
                            >
                              {dropdown_yes_or_no.map(
                                (hasFinanceClearanceCertificate) => (
                                  <MenuItem
                                    key={hasFinanceClearanceCertificate.text}
                                    value={hasFinanceClearanceCertificate.text}
                                  >
                                    {hasFinanceClearanceCertificate.text}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="hasID-label">BI</InputLabel>
                            <Select
                              labelId="hasID-label"
                              value={candidate.hasID}
                              label="BI"
                              onChange={handleSelectChange("hasID")}
                              required
                              error={errorsCampany.hasID}
                            >
                              {dropdown_yes_or_no.map((hasID) => (
                                <MenuItem key={hasID.text} value={hasID.text}>
                                  {hasID.text}
                                </MenuItem>
                              ))}
                            </Select>{" "}
                            {errorsCampany.hasID && (
                              <FormHelperText error>
                                Campo obrigatório
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="hasResidenceProof-label">
                              Comprovativo de Residência
                            </InputLabel>
                            <Select
                              labelId="hasResidenceProof-label"
                              value={candidate.hasResidenceProof}
                              label="Comprovativo de Residência"
                              onChange={handleSelectChange("hasResidenceProof")}
                            >
                              {dropdown_yes_or_no.map((hasResidenceProof) => (
                                <MenuItem
                                  key={hasResidenceProof.text}
                                  value={hasResidenceProof.text}
                                >
                                  {hasResidenceProof.text}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="hasTaxNumber-label">
                              NUIT Pessoal
                            </InputLabel>
                            <Select
                              labelId="hasTaxNumber-label"
                              value={candidate.hasTaxNumber}
                              label="NUIT"
                              onChange={handleSelectChange("hasTaxNumber")}
                              required
                              error={errorsCampany.hasTaxNumber}
                            >
                              {dropdown_yes_or_no.map((hasTaxNumber) => (
                                <MenuItem
                                  key={hasTaxNumber.text}
                                  value={hasTaxNumber.text}
                                >
                                  {hasTaxNumber.text}
                                </MenuItem>
                              ))}
                            </Select>{" "}
                            {errorsCampany.hasTaxNumber && (
                              <FormHelperText error>
                                Campo obrigatório
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="incomeStatement-label">
                              Declaração de Rendimentos
                            </InputLabel>
                            <Select
                              labelId="incomeStatement-label"
                              value={candidate.incomeStatement}
                              label="Declaração de Rendimentos"
                              onChange={handleSelectChange("incomeStatement")}
                            >
                              {dropdown_yes_or_no.map((incomeStatement) => (
                                <MenuItem
                                  key={incomeStatement.text}
                                  value={incomeStatement.text}
                                >
                                  {incomeStatement.text}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="size-label">
                              Registo Criminal
                            </InputLabel>
                            <Select
                              labelId="size-label"
                              value={candidate.hasCriminalRecord}
                              label="Registo Criminal"
                              onChange={handleSelectChange("hasCriminalRecord")}
                            >
                              {dropdown_yes_or_no.map((hasCriminalRecord) => (
                                <MenuItem
                                  key={hasCriminalRecord.text}
                                  value={hasCriminalRecord.text}
                                >
                                  {hasCriminalRecord.text}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {selectedRequirement === "particulares" && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          Requisitos para Particulares
                        </Typography>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="hasID-label">BI</InputLabel>
                            <Select
                              labelId="hasID-label"
                              value={candidate.hasID}
                              label="BI"
                              onChange={handleSelectChange("hasID")}
                              required
                              error={errorsPerson.hasID}
                            >
                              {dropdown_yes_or_no.map((hasID) => (
                                <MenuItem key={hasID.text} value={hasID.text}>
                                  {hasID.text}
                                </MenuItem>
                              ))}
                            </Select>{" "}
                            {errorsPerson.hasID && (
                              <FormHelperText error>
                                Campo obrigatório
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="hasResidenceProof-label">
                              Comprovativo de Residência
                            </InputLabel>
                            <Select
                              labelId="hasResidenceProof-label"
                              value={candidate.hasResidenceProof}
                              label="Comprovativo de Residência"
                              onChange={handleSelectChange("hasResidenceProof")}
                            >
                              {dropdown_yes_or_no.map((hasResidenceProof) => (
                                <MenuItem
                                  key={hasResidenceProof.text}
                                  value={hasResidenceProof.text}
                                >
                                  {hasResidenceProof.text}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="hasTaxNumber-label">
                              NUIT
                            </InputLabel>
                            <Select
                              labelId="hasTaxNumber-label"
                              value={candidate.hasTaxNumber}
                              label="NUIT"
                              onChange={handleSelectChange("hasTaxNumber")}
                              required
                              error={errorsPerson.hasTaxNumber}
                            >
                              {dropdown_yes_or_no.map((hasTaxNumber) => (
                                <MenuItem
                                  key={hasTaxNumber.text}
                                  value={hasTaxNumber.text}
                                >
                                  {hasTaxNumber.text}
                                </MenuItem>
                              ))}
                            </Select>{" "}
                            {errorsPerson.hasTaxNumber && (
                              <FormHelperText error>
                                Campo obrigatório
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="incomeStatement-label">
                              Declaração de Rendimentos
                            </InputLabel>
                            <Select
                              labelId="incomeStatement-label"
                              value={candidate.incomeStatement}
                              label="Declaração de Rendimentos"
                              onChange={handleSelectChange("incomeStatement")}
                            >
                              {dropdown_yes_or_no.map((incomeStatement) => (
                                <MenuItem
                                  key={incomeStatement.text}
                                  value={incomeStatement.text}
                                >
                                  {incomeStatement.text}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="size-label">
                              Registo Criminal
                            </InputLabel>
                            <Select
                              labelId="size-label"
                              value={candidate.hasCriminalRecord}
                              label="Registo Criminal"
                              onChange={handleSelectChange("hasCriminalRecord")}
                            >
                              {dropdown_yes_or_no.map((hasCriminalRecord) => (
                                <MenuItem
                                  key={hasCriminalRecord.text}
                                  value={hasCriminalRecord.text}
                                >
                                  {hasCriminalRecord.text}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Caso tenha seleccionado a opção 'Não' para os campos descritos acima, por favor justifique."
                        value={candidate.noDocumentJustification}
                        onChange={(e) =>
                          handleChange(
                            "noDocumentJustification",
                            e.target.value
                          )
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              {activeStep === 5 && (
                <Box p={3}>
                  <Typography variant="h6">
                    Informação sobre a gestão da empresa do candidato
                  </Typography>

                  <Grid container spacing={2}>
                    {/* 5.1 Footprint e Capacidade Técnica */}
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Footprint e Capacidade Técnica
                      </Typography>
                    </Grid>

                    {/* Representação Regional */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasRegionalRepresentation-label">
                            A Empresa do Candidato possui representação na
                            região onde pretende prestar os serviços?*
                          </InputLabel>
                          <Select
                            labelId="hasRegionalRepresentation-label"
                            value={
                              candidate.candidateManagement
                                .hasRegionalRepresentation
                            }
                            label="A Empresa do Candidato possui representação na região onde pretende prestar os serviços?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasRegionalRepresentation: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se sim, por favor indique."
                          value={
                            candidate.candidateManagement
                              .regionalRepresentationDetails
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                regionalRepresentationDetails: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Expansão Geográfica */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="plansToExpandGeographies-label">
                            A Empresa do Candidato pretende expandir as suas
                            actividades nos próximos anos para outras
                            geografias?*
                          </InputLabel>
                          <Select
                            labelId="plansToExpandGeographies-label"
                            value={
                              candidate.candidateManagement
                                .plansToExpandGeographies
                            }
                            label="A Empresa do Candidato pretende expandir as suas actividades nos próximos anos para outras geografias?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  plansToExpandGeographies: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se sim, por favor indique e justifique-se."
                          value={candidate.candidateManagement.expansionDetails}
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                expansionDetails: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Fornecimento de Serviços fora das Regiões */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="providesServicesOutsideRegions-label">
                            A Empresa do Candidato fornece os seus Bens e
                            Serviços para outras regiões em que não tenha
                            sucursais (se aplicável)?*
                          </InputLabel>
                          <Select
                            labelId="providesServicesOutsideRegions-label"
                            value={
                              candidate.candidateManagement
                                .providesServicesOutsideRegions
                            }
                            label="A Empresa do Candidato fornece os seus Bens e Serviços para outras regiões em que não tenha sucursais (se aplicável)?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  providesServicesOutsideRegions:
                                    e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se sim, por favor indique."
                          value={
                            candidate.candidateManagement
                              .serviceOutsideRegionsDetails
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                serviceOutsideRegionsDetails: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Processos de HSE */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasHSEProcesses-label">
                            A Empresa do Candidado possui processos de HSE
                            (Saúde e Segurança no Trabalho) instituído na sua
                            empresa?*
                          </InputLabel>
                          <Select
                            labelId="hasHSEProcesses-label"
                            value={
                              candidate.candidateManagement.hasHSEProcesses
                            }
                            label="A Empresa do Candidado possui processos de HSE (Saúde e Segurança no Trabalho) instituído na sua empresa?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasHSEProcesses: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se sim, por favor descreva."
                          value={
                            candidate.candidateManagement.hseProcessesDetails
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                hseProcessesDetails: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Manutenção */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="performsMaintenance-label">
                            Existe algum tipo de manutenção (programada ou
                            corretiva) executada pela Empresa do Candidato? (Ex:
                            Equipamentos)*
                          </InputLabel>
                          <Select
                            labelId="performsMaintenance-label"
                            value={
                              candidate.candidateManagement.performsMaintenance
                            }
                            label="Existe algum tipo de manutenção (programada ou corretiva) executada pela Empresa do Candidato? (Ex: Equipamentos)*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  performsMaintenance: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se sim, por favor descreva."
                          value={
                            candidate.candidateManagement.maintenanceDetails
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                maintenanceDetails: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Unidade de Controle de Qualidade */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasQualityControlUnit-label">
                            A Empresa do Candidato possui alguma Unidade de
                            Controlo de Qualidade na sua estrutura
                            organizacional?*
                          </InputLabel>
                          <Select
                            labelId="hasQualityControlUnit-label"
                            value={
                              candidate.candidateManagement
                                .hasQualityControlUnit
                            }
                            label="A Empresa do Candidato possui alguma Unidade de Controlo de Qualidade na sua estrutura organizacional?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasQualityControlUnit: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se sim, por favor descreva."
                          value={
                            candidate.candidateManagement
                              .qualityControlUnitDetails
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                qualityControlUnitDetails: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Competências Técnicas */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasTechnicalCompetence-label">
                            A Empresa do Candidato possui Competências Técnicas
                            para responder a demanda do seu sector?*
                          </InputLabel>
                          <Select
                            labelId="hasTechnicalCompetence-label"
                            value={
                              candidate.candidateManagement
                                .hasTechnicalCompetence
                            }
                            label="A Empresa do Candidato possui Competências Técnicas para responder a demanda do seu sector?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasTechnicalCompetence: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se sim, por favor descreva."
                          value={
                            candidate.candidateManagement
                              .technicalCompetenceDetails
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                technicalCompetenceDetails: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* 5.2 Capacidade de Gestão do Negócio */}
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Capacidade de Gestão do Negócio
                      </Typography>
                    </Grid>

                    {/* Código de Ética */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasEthicsCode-label">
                            A Empresa do Candidato possui um Código de Ética em
                            que actua em conformidade? Se sim, anexe o
                            comprovativo.
                          </InputLabel>
                          <Select
                            labelId="hasEthicsCode-label"
                            value={candidate.candidateManagement.hasEthicsCode}
                            label="A Empresa do Candidato possui um Código de Ética em que actua em conformidade? Se sim, anexe o comprovativo."
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasEthicsCode: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .ethicsCodeJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                ethicsCodeJustification: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Estrutura Orgânica */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasOrganizationalStructure-label">
                            A Empresa do Candidato possui uma Estrutura Orgânica
                            que evidencie segregação de funções? Se sim, anexe o
                            organograma.
                          </InputLabel>
                          <Select
                            labelId="hasOrganizationalStructure-label"
                            value={
                              candidate.candidateManagement
                                .hasOrganizationalStructure
                            }
                            label="A Empresa do Candidato possui uma Estrutura Orgânica que evidencie segregação de funções? Se sim, anexe o organograma."
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasOrganizationalStructure: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .organizationalStructureJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                organizationalStructureJustification:
                                  e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Processos e Procedimentos */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasDefinedProcesses-label">
                            A Empresa do Candidato possui processos e
                            procedimentos devidamente definidos de acordo com as
                            suas actividades?
                          </InputLabel>
                          <Select
                            labelId="hasDefinedProcesses-label"
                            value={
                              candidate.candidateManagement.hasDefinedProcesses
                            }
                            label="A Empresa do Candidato possui processos e procedimentos devidamente definidos de acordo com as suas actividades?"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasDefinedProcesses: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .definedProcessesJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                definedProcessesJustification: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Planeamento de Compras */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasPurchasePlanning-label">
                            A Empresa do Candidato possui algum planeamento para
                            realizar as suas compras?
                          </InputLabel>
                          <Select
                            labelId="hasPurchasePlanning-label"
                            value={
                              candidate.candidateManagement.hasPurchasePlanning
                            }
                            label="A Empresa do Candidato possui algum planeamento para realizar as suas compras?"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasPurchasePlanning: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .purchasePlanningJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                purchasePlanningJustification: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* 5.3 Recursos Humanos */}
                    <Grid item xs={12}>
                      <Typography variant="h6"> Recursos Humanos</Typography>
                    </Grid>

                    {/* Funções/Departamentos Estruturados */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasStructuredFunctions-label">
                            A Empresa do Candidato possui as suas
                            funções/departamentos estruturados de acordo com o
                            seu tipo de negócio?*
                          </InputLabel>
                          <Select
                            labelId="hasStructuredFunctions-label"
                            value={
                              candidate.candidateManagement
                                .hasStructuredFunctions
                            }
                            label="A Empresa do Candidato possui as suas funções/departamentos estruturados de acordo com o seu tipo de negócio?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasStructuredFunctions: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .structuredFunctionsJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                structuredFunctionsJustification:
                                  e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Recursos Adequados */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasAdequateResources-label">
                            A Empresa do Candidato possui os recursos adequados
                            em número e qualidade para desempenhar as suas
                            actividades?*
                          </InputLabel>
                          <Select
                            labelId="hasAdequateResources-label"
                            value={
                              candidate.candidateManagement.hasAdequateResources
                            }
                            label="A Empresa do Candidato possui os recursos adequados em número e qualidade para desempenhar as suas actividades?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasAdequateResources: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .adequateResourcesJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                adequateResourcesJustification: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Igualdade de Género */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasGenderEquality-label">
                            Existe uma distribuição equitiva na igualdade de
                            género, na Empresa do Beneficiário?*
                          </InputLabel>
                          <Select
                            labelId="hasGenderEquality-label"
                            value={
                              candidate.candidateManagement.hasGenderEquality
                            }
                            label="Existe uma distribuição equitiva na igualdade de género, na Empresa do Beneficiário?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasGenderEquality: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .genderEqualityJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                genderEqualityJustification: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Rotatividade */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasHighTurnover-label">
                            A Empresa do Candidato possui um elevado nível de
                            rotatividade?*
                          </InputLabel>
                          <Select
                            labelId="hasHighTurnover-label"
                            value={
                              candidate.candidateManagement.hasHighTurnover
                            }
                            label="A Empresa do Candidato possui um elevado nível de rotatividade?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasHighTurnover: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se sim, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .highTurnoverJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                highTurnoverJustification: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* 5.4 Informação Financeira */}
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Informação Financeira
                      </Typography>
                    </Grid>

                    {/* Regime de Tributação */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label="Por favor, indique o Regime de Tributação da Empresa do Candidato?"
                          value={candidate.candidateManagement.taxRegime}
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                taxRegime: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Qual é a data de reporte mais recente das Demonstrações Financeiras da Empresa do Candidato?*"
                          value={
                            candidate.candidateManagement
                              .latestFinancialReportDate
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                latestFinancialReportDate: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Volume de Negócios e Auditoria */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label="Volume de Negócios Anual da Empresa do Candidato"
                          value={candidate.candidateManagement.annualTurnover}
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                annualTurnover: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="As Demonstrações da Empresa são Auditadas por um
                              auditor Independente? Se sim, por favor indique a
                              frequência."
                          value={
                            candidate.candidateManagement
                              .financialStatementsAudited
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                financialStatementsAudited: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Frequência de Produção das Demonstrações Financeiras */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="financialStatementsFrequency-label">
                            Frequência de produção das Demonstrações Financeiras
                            da Empresa do Candidato
                          </InputLabel>
                          <Select
                            labelId="financialStatementsFrequency-label"
                            value={
                              candidate.candidateManagement
                                .financialStatementsFrequency
                            }
                            label="Frequência de produção das Demonstrações Financeiras da Empresa do Candidato"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  financialStatementsFrequency: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_quarter_year.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Por favor, forneça as Demonstrações Financeiras dos últimos 3 anos Financeiros (se aplicável)"
                          value={
                            candidate.candidateManagement
                              .financialStatementsLast3Years
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                financialStatementsLast3Years: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* 5.5 Sistemas e Equipamentos */}
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Sistemas e Equipamentos
                      </Typography>
                    </Grid>

                    {/* Equipamentos Adequados */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasAdequateEquipment-label">
                            A Empresa do Candidato possui equipamentos adequados
                            para desempenhar as suas actividades?*
                          </InputLabel>
                          <Select
                            labelId="hasAdequateEquipment-label"
                            value={
                              candidate.candidateManagement.hasAdequateEquipment
                            }
                            label="A Empresa do Candidato possui equipamentos adequados para desempenhar as suas actividades?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasAdequateEquipment: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .adequateEquipmentJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                adequateEquipmentJustification: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Sistemas Adequados */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasAdequateSystems-label">
                            A Empresa do Candidato possui sistemas adequados
                            para desempenhar as suas actividades?*
                          </InputLabel>
                          <Select
                            labelId="hasAdequateSystems-label"
                            value={
                              candidate.candidateManagement.hasAdequateSystems
                            }
                            label="A Empresa do Candidato possui sistemas adequados para desempenhar as suas actividades?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasAdequateSystems: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .adequateInfrastructureJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                adequateInfrastructureJustification:
                                  e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Infraestruturas Adequadas */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasAdequateInfrastructure-label">
                            A Empresa do Candidato possui infraestruturas
                            adequadas às suas actividades?* Se sim, por favor
                            forneça Imagens das suas instalações
                          </InputLabel>
                          <Select
                            labelId="hasAdequateInfrastructure-label"
                            value={
                              candidate.candidateManagement
                                .hasAdequateInfrastructure
                            }
                            label="A Empresa do Candidato possui infraestruturas adequadas às suas actividades?* Se sim, por favor forneça Imagens das suas instalações"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasAdequateInfrastructure: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .adequateSystemsJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                adequateSystemsJustification: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}

              <Box mt={2} mb={2} display="flex" justifyContent="space-between">
                <Button
                  variant="outlined"
                  disabled={activeStep === 0}
                  onClick={() => handleStepChange(activeStep - 1, "back")}
                  sx={{ mr: 2 }}
                >
                  Anterior
                </Button>

                {activeStep !== formtype && (
                  <Button
                    variant="outlined"
                    onClick={() => handleStepChange(activeStep + 1, "next")}
                    sx={{ mx: 2 }}
                  >
                    Próximo
                  </Button>
                )}

                {activeStep === formtype &&
                  (candidatureStatus === "Não publicada" ||
                  candidatureStatus === null ||
                  finalBtn === true ||
                  candidatureStatus === "" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (id > 0 && userType != "ROLE_CANDIDATE") {
                          toast.warning(
                            `Somente o Candidato ${candidate.candidateName} é que deve atualizar os dados`
                          );
                        } else {
                          handleSubmit();
                        }
                      }}
                    >
                      Concluir
                    </Button>
                  ) : (
                    <>
                      <Button variant="contained" color="primary" disabled>
                        Estado: {candidatureStatus}
                      </Button>
                    </>
                  ))}
              </Box>
            </div>
          </Box>
        </CardContent>
      </Card>

      <BackButton />
    </>
  );
};

export default CandidateForm;
