import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Card as MuiCard,
  CardContent,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import { spacing } from "@mui/system";
import useLookups from "../../hooks/useLookups";
import http from "../../utils/axios";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import OlogaSelect from "../../components/select/OlogaSelect";

import OlogaButtonGroup from "../../components/Buttons/OlogaButtonGroup";
import { assessmentSchema } from "../../utils/formValidation";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Card = styled(MuiCard)(spacing);

interface AssessmentFormProps {
  assessment?: any;
  CandidateView?: boolean;
}

function AssessmentForm({ assessment, CandidateView }: AssessmentFormProps) {
  const [assessmentName, setAssessmentName] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      if (assessment?.id) {
        await http.put(`/assessments/create/`, values);
        navigate(-1);
      } else {
        await http.post(`/assessments/create/`, values);
        navigate(-1);
      }
      toast.success("Assessment created");
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
      toast.error(error.message || "Error saving vendor");
    }
  };

  useEffect(() => {
    if (assessment?.id) {
      const fetchData = async () => {
        try {
          const result = await http.get("/assessments/" + assessment?.id);
          assessment.id = result.data.id;
          assessment.name = result.data.name;
          setAssessmentName(result.data.name);
        } catch (error) {}
      };
      fetchData();
    }
  }, [assessment]);

  return (
    <Formik
      initialValues={assessment}
      validationSchema={assessmentSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, status, errors }) => (
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Typography variant="h6" gutterBottom>
                {CandidateView ? "Assessment:" : "Assessment Details"}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {CandidateView ? (
                    <Typography variant="h6" gutterBottom>
                      {assessmentName}
                    </Typography>
                  ) : (
                    <OlogaTextfield
                      name="name"
                      label="Name"
                      disabled={CandidateView}
                    />
                  )}
                </Grid>
              </Grid>
              {CandidateView == true ? <></> : <OlogaButtonGroup />}
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export default AssessmentForm;
