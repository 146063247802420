import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Paper as MuiPaper,
  Button,
} from "@mui/material";
import { GridColDef, GridActionsCellItem, GridRowId } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import OlogaDataTableNormal from "../../components/tables/OlogaDataTableNormal";
import http from "../../utils/axios";
import CancelButton from "../../components/Buttons/CancelButton";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import { HealthAndSafety, VisibilityOutlined } from "@mui/icons-material";
import TransactionFixerModal from "./TransactionFixerModal";
import OlogaLoading from "../../components/misc/OlogaLoading";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function BankReconDetail() {
  const [url, setUrl] = useState("");
  const [event, setEvent] = useState({
    id: "",
    name: "",
    countryName: "",
  });
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    role: "",
  });
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedTxMetadata, setSelectedTxMetadata] = useState<any>({});
  const [showLoading, setShowLoading] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "vendorPagamio",
      headerName: "Pagamio Vendor",
      flex: 1,
      editable: false,
    },
    {
      field: "referencePagamio",
      headerName: "Pagamio Reference",
      flex: 1,
      editable: false,
    },
    {
      field: "timePagamio",
      headerName: "Pagamio Time",
      flex: 1,
      editable: false,
    },
    {
      field: "valuePagamio",
      headerName: "Pagamio Amount",
      flex: 1,
      editable: false,
    },
    {
      field: "referenceBank",
      headerName: "Bank Reference",
      flex: 1,
      editable: false,
    },
    {
      field: "timeBank",
      headerName: "Bank Time",
      flex: 1,
      editable: false,
    },
    {
      field: "valueBank",
      headerName: "Bank Amount",
      flex: 1,
      editable: false,
    },
    {
      field: "diference",
      headerName: "Diference",
      flex: 0.5,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Ações",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          disabled={params.row.referencePagamio == " "}
          icon={<VisibilityOutlined />}
          onClick={() => {
            navigate("/finance/saledetail", {
              state: {
                transactionCode: params.id,
                returnUrl: "/finance/recondetail",
                scenario: state.scenario,
                eventId: state.eventId,
              },
            });
          }}
          label="Details"
        />,
        <GridActionsCellItem
          disabled={params.row.referencePagamio != " "}
          icon={<HealthAndSafety />}
          onClick={() => {
            setSelectedTxMetadata({
              eventId: state.eventId,
              transactionCode: params.row.referenceBank,
              transactionDate: params.row.timeBank,
              totalAmount: params.row.valueBank,
            });
            setOpenDetailsModal(true);
          }}
          label="Details"
        />,
      ],
    },
  ];

  const getData = async () => {
    try {
      const response = await http.get(`/events/unmasked/${state.eventId}`);
      setEvent(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    setShowLoading(true);
    getData();
    setUrl(
      `/banktx/reconcile-detail/q?type=${state.scenario}&eventId=${state.eventId}`
    );
    setShowLoading(false);
  }, []);

  function EventDetailInfo() {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Event : {event.name}
          </Typography>
        </CardContent>
      </Card>
    );
  }
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Bank Reconcile Detail
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Home
        </Link>
        <Button
          onClick={() => {
            navigate("/finance/recon", {
              state: {
                eventId: state.eventId,
              },
            });
          }}
          name="Detail"
        >
          Bank Reconcile
        </Button>
        <Typography>Bank Reconcile Detail</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      <EventDetailInfo />
      <OlogaLoading open={showLoading} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid url={url} columns={columns} />
          </Card>
        </Grid>

        <Grid
          container
          marginTop={5}
          spacing={3}
          mt={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Grid item marginRight={2}>
            <Button
              onClick={() => {
                navigate("/finance/recon", {
                  state: {
                    eventId: state.eventId,
                  },
                });
              }}
              name="Detail"
              variant="outlined"
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <TransactionFixerModal
        open={openDetailsModal}
        onClose={() => {
          setOpenDetailsModal(false);
        }}
        txMetadata={selectedTxMetadata}
      />
    </React.Fragment>
  );
}

export default BankReconDetail;
