import { useEffect, useState } from "react";
import { Formik } from "formik";

import http from "../../utils/axios";
import useVendors from "../../hooks/useVendors";
// import OlogaButtonGroup from "../../components/Buttons/OlogaButtonGroup";
import OlogaSelect from "../../components/select/OlogaSelect";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import { stationSchema } from "../../utils/formValidation";
import OlogaAlert from "../../components/misc/OlogaAlert";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Grid } from "@mui/material";
import OlogaConfirmDialog from "../../components/misc/OlogaConfirmDialog";
import { toast } from "react-toastify";
import { is } from "date-fns/locale";
import OlogaButtonGroup from "../../components/Buttons/OlogaButtonGroup";

interface StationFormProps {
  station: any;
  isVendor: boolean;
  vendorId?: any;
}

function StationForm({ station, isVendor, vendorId }: StationFormProps) {
  const { vendors } = useVendors();
  const [events, setEvents] = useState<any>([]);
  const [selectedEventId, setSelectedEventId] = useState(
    isVendor ? station.eventId : ""
  );
  const [selectedVendorId, setSelectedVendorId] = useState(
    isVendor ? vendorId : ""
  );
  const [url, setUrl] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedStation, setSelectedStation] = useState({
    ...station,
    vendorId: vendorId,
  });
  const [toDelete, setToDeleted] = useState<any>({});

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: true,
    },
    {
      field: "eventName",
      headerName: "Event",
      flex: 1,
      editable: false,
    },
    {
      field: "venueName",
      headerName: "Venue",
      flex: 1,
      editable: false,
    },
    {
      field: "vendorName",
      headerName: "Vendor",
      flex: 1,
      editable: false,
    },
    {
      field: "geoLocation",
      headerName: "Geo Location",
      flex: 1,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Ações",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => {
            setSelectedStation({ ...params.row });
          }}
          label="Edit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => {
            if (params.row.id) {
              setToDeleted({ ...params.row });
              setOpenDialog(true);
            }
          }}
          label="Delete"
        />,
      ],
    },
  ];

  const deleteStation = async () => {
    if (toDelete?.id) {
      try {
        setUrl("");
        const result = await http.delete(`/stations/${toDelete?.id}`);
        setOpenDialog(false);
        setUrl(
          `/stations/event/${Number(selectedEventId)}/vendor/${Number(
            selectedVendorId
          )}`
        );
        toast.success("Station deleted successfully!");
      } catch (error: any) {
        setOpenDialog(false);
        toast.error(error.message || "Error deleting station");
      }
    }
  };

  const handleEventChange = (value: any) => {
    setUrl("");
    setSelectedEventId(value);
    station.eventId = value;
    station.vendorId = selectedVendorId;
    setUrl(
      `/stations/event/${Number(value)}/vendor/${Number(selectedVendorId)}`
    );
  };

  const handleSubmit = async (
    values: any,
    { setSubmitting, resetForm }: any
  ) => {
    try {
      setUrl("");
      const url = "/stations";
      if (selectedStation.id) {
        await http.put(url, values);
      } else {
        await http.post(url, values);
      }
      resetForm();
      setSelectedStation({
        id: "",
        name: "",
        geoLocation: "",
        vendorId: selectedVendorId,
        eventId: selectedEventId,
      });
      setSubmitting(false);

      setUrl(
        `/stations/event/${Number(selectedEventId)}/vendor/${Number(
          selectedVendorId
        )}`
      );
      toast.success("Station saved successfully!");
    } catch (error: any) {
      setSubmitting(false);
      toast.error(error.message || "Error saving station");
    }
  };

  useEffect(() => {
    if (selectedStation?.id) {
      setSelectedVendorId(selectedStation.vendorId);
      handleEventChange(selectedStation.eventId);
      setUrl(
        `/stations/event/${Number(selectedStation.eventId)}/vendor/${Number(
          selectedStation.vendorId
        )}`
      );
    }
    if (isVendor) {
      setUrl(
        `/stations/event/${Number(selectedStation.eventId)}/vendor/${Number(
          selectedStation.vendorId
        )}`
      );
    }
  }, [selectedStation]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedVendorId) {
          try {
            if (isVendor) {
              const response = await http.get(
                `/events/vendor/${selectedVendorId}/names`
              );
              setEvents(response.data);
            } else {
              const response = await http.get(
                station?.id
                  ? `/events/vendor/${selectedVendorId}/names`
                  : `/events/upcoming/vendor/${selectedVendorId}/names`
              );
              setEvents(response.data);
            }
          } catch (error) {}
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [selectedVendorId]);

  return (
    <>
      <Formik
        initialValues={{ ...selectedStation }}
        validationSchema={stationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <OlogaConfirmDialog
              title="Delete Station"
              description={`Delete ${toDelete.name}?`}
              open={openDialog}
              setOpen={setOpenDialog}
              handleConfirm={deleteStation}
            />

            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <OlogaSelect
                  name="vendorId"
                  label="Vendor"
                  options={vendors}
                  setValue={setSelectedVendorId}
                  disabled={isVendor}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <OlogaSelect
                  name="eventId"
                  label="Event"
                  options={events}
                  setValue={handleEventChange}
                  disabled={isVendor}
                />
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <OlogaTextfield name="name" label="Name" />
              </Grid>
              <Grid item xs={12} md={6}>
                <OlogaTextfield name="geoLocation" label="Geo Location" />
              </Grid>
            </Grid>
            <OlogaButtonGroup />
          </form>
        )}
      </Formik>
      <Box mt={10}>
        <OlogaDataGrid url={url} columns={columns} isPagedContent={false} />
      </Box>
    </>
  );
}

export default StationForm;
