import React from "react";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Card as MuiCard, Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";

const AssessmentRead: React.FC = () => {
  const Card = styled(MuiCard)(spacing);
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      editable: false,
    },

    {
      field: "actions",
      headerName: "Ações",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <Button
          size="small"
          variant="outlined"
          startIcon={<VisibilityOutlinedIcon />}
          onClick={() => {
            console.log("log:", params);
            navigate(`/assessments/edit`, {
              state: { id: params.id },
            });
          }}
        >
          View
        </Button>,
      ],
    },
  ];

  return (
    <React.Fragment>
      <OlogaPageHeader
        title={"Assessments"}
        buttonLabel="New Assessment"
        onClick={() => navigate("/assessments/create")}
      ></OlogaPageHeader>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid url={"/assessments"} columns={columns} />
          </Card>
          <OlogaBackButton />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AssessmentRead;
