import React, { useEffect, useState } from "react";
import { TextField, Button, Grid, Box, CardContent, Card } from "@mui/material";
import { Formik, Form, Field, FormikHelpers, ErrorMessage } from "formik";
import * as Yup from "yup";
import http from "../../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface ActionPlanFormValues {
  id: number;
  responsable: string;
  impact: string;
  startDate: string;
  endDate: string;
  status: number;
  actionPlanId: number;
  actionScoreItemId: number;
  assessmentScoreId: number;
  candidateId: number;
  fullName: string;
  fullNameGap: string;
  fullNameCandidate: string;
  analysCategory: string;
  specificAditionalNeeds: string;
  category: string;
  subActivitySector: string;
  companyType: string;
  gapAssessmentId: number;
}

const ActionPlanForm: React.FC<{
  assessmentScoreId: number;
  onClose: () => void;
  actionPlanDto: any;
}> = ({ assessmentScoreId, onClose, actionPlanDto }) => {
  const [initialValues, setInitialValues] = useState<ActionPlanFormValues>({
    id: actionPlanDto.id ?? 0,
    responsable: actionPlanDto.responsabe ?? "",
    impact: actionPlanDto.impact ?? "",
    startDate: actionPlanDto.startDate ?? "",
    endDate: actionPlanDto.endDate ?? "",
    status: actionPlanDto.status ?? 0,
    actionPlanId: actionPlanDto.actionPlanId ?? 0,
    actionScoreItemId: 0,
    assessmentScoreId: assessmentScoreId,
    candidateId: actionPlanDto.candidateId,
    fullName: actionPlanDto.fullName ?? "",
    fullNameGap: actionPlanDto.fullNameGap ?? "",
    fullNameCandidate: actionPlanDto.fullNameCandidate ?? "",
    analysCategory: actionPlanDto.analysCategory ?? "",
    specificAditionalNeeds: actionPlanDto.specificAditionalNeeds ?? "",
    category: actionPlanDto.category ?? "",
    subActivitySector: actionPlanDto.subActivitySector ?? "",
    companyType: actionPlanDto.companyType ?? "",
    gapAssessmentId: actionPlanDto.gapAssessmentId ?? 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    console.log(actionPlanDto);
    if (actionPlanDto.actionPlanId != null) {
      const fetchData = async () => {
        try {
          if (actionPlanDto?.status != "No Plano de Ação found") {
            const response = await http.get(
              `/actionPlan/${actionPlanDto.actionPlanId}`
            );
            if (response.data) {
              setInitialValues(response.data);
            }
          }
        } catch (error) {
          console.error("Error fetching Plano de Ação:", error);
        }
      };
      fetchData();
    }
  }, [assessmentScoreId, actionPlanDto]);

  const handleSubmit = async (
    values: ActionPlanFormValues,
    actions: FormikHelpers<ActionPlanFormValues>
  ) => {
    try {
      console.log(values);
      values["candidateId"] = actionPlanDto.candidateId;
      values["gapAssessmentId"] = actionPlanDto.gapAssessmentId;
      values["status"] = 0;

      if (actionPlanDto?.status != "No Action Plan found") {
        // if (values.id > 0) {
        values["id"] = actionPlanDto.actionPlanId;

        await http.put(`/actionPlan/${actionPlanDto.actionPlanId}`, values);
        toast.success("Plano de Ação Updated successfully");
        navigate("/assessment-admin/action-plan");

        // }
      } else {
        const response = await http.post("/actionPlan", values);
        if (response.status === 200)
          toast.success("Plano de Ação criado com sucesso");
      }
      onClose(); // Fechar o modal após salvar
    } catch (error) {
      console.error("Error creating/updating Plano de Ação:", error);
      toast.error("An error occurred while saving the Plano de Ação");
    } finally {
      actions.setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    responsable: Yup.string().required("Responsable is required"),
    impact: Yup.string().required("Impact is required"),
    startDate: Yup.date().required("Start Date is required"),
    endDate: Yup.date()
      .required("End Date is required")
      .min(Yup.ref("startDate"), "End Date can't be before Start Date"),
  });

  return (
    <>
      <Card>
        <CardContent>
          <Box>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ values, handleChange, isSubmitting, errors, touched }) => (
                <Form>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          name="responsable"
                          label="Responsable"
                          fullWidth
                          value={values.responsable}
                          onChange={handleChange}
                          error={
                            touched.responsable && Boolean(errors.responsable)
                          }
                          helperText={<ErrorMessage name="responsable" />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          name="impact"
                          label="Impact"
                          fullWidth
                          value={values.impact}
                          onChange={handleChange}
                          error={touched.impact && Boolean(errors.impact)}
                          helperText={<ErrorMessage name="impact" />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          name="startDate"
                          label="Start Date"
                          type="date"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={values.startDate}
                          onChange={handleChange}
                          error={touched.startDate && Boolean(errors.startDate)}
                          helperText={<ErrorMessage name="startDate" />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          name="endDate"
                          label="End Date"
                          type="date"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={values.endDate}
                          onChange={handleChange}
                          error={touched.endDate && Boolean(errors.endDate)}
                          helperText={<ErrorMessage name="endDate" />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justifyContent="flex-end">
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                          >
                            {(actionPlanDto?.status ==
                              "No Action Plan found") ==
                            null
                              ? "Create"
                              : "Update"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default ActionPlanForm;
