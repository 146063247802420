const shortMonthDateFormat: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

const locale = "en-ZA";

// Transform a date string in the format "dd/mm/yyyy" to a Date object
const ddMmYyyyToDate = (inputDate: string): Date | undefined => {
  if (inputDate === undefined) return undefined;
  const [day, month, year] = inputDate.split("/").map(Number);
  return new Date(year, month - 1, day);
};

const formatDate = (
  value: Date | any,
  locale: any,
  formatOptions: Intl.DateTimeFormatOptions
): string => {
  const formattedDate = new Intl.DateTimeFormat(locale, formatOptions).format(
    value
  );
  return formattedDate.toString();
};

const formatDateToShortMonth = (dateToFormat: any): string => {
  return dateToFormat
    ? formatDate(ddMmYyyyToDate(dateToFormat), locale, shortMonthDateFormat)
    : "N/A";
};

const formatDateToDdMmYyyy = (value: any): string => {
  const formattedDate = new Intl.DateTimeFormat("pt-PT", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(new Date(value));

  return formattedDate.toString();
};

function revertDate(inputDate: string): Date | undefined {
  if (inputDate === undefined) return undefined;
  const [day, month, year] = inputDate.split("/").map(Number);
  return new Date(year, month - 1, day);
}

function revertTime(timeString: string): Date | null {
  const timeParts = timeString.split(":");

  if (timeParts.length === 2) {
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);

    if (!isNaN(hours) && !isNaN(minutes)) {
      const currentDate = new Date();
      currentDate.setHours(hours);
      currentDate.setMinutes(minutes);

      return currentDate;
    }
  }

  // Return null if the input is not in the expected format
  return null;
}

const formatTime = (value: any): string => {
  const formattedTime = new Intl.DateTimeFormat("en-ZA", {
    hour: "2-digit",
    minute: "2-digit",
  }).format(new Date(value));

  return formattedTime;
};

const formatMonetaryValue = (value: number): string => {
  const formattedValue = (value ? value : 0).toLocaleString("en-US", {
    style: "currency",
    currency: "ZAR",
  });

  return formattedValue;
};

const formatNumber = (value: number): string => {
  return value
    ? value.toLocaleString("en-US", {
        style: "decimal",
      })
    : "N/A";
};

const holdOn = (time: number) => new Promise((res) => setTimeout(res, time));

const cleanHeanders = (data: any[], fieldsToRemove: string[]): any[] => {
  return data.filter((item) => !fieldsToRemove.includes(item.field));
};

export {
  formatDate,
  formatTime,
  holdOn,
  revertDate,
  revertTime,
  formatMonetaryValue,
  formatDateToShortMonth,
  formatDateToDdMmYyyy,
  formatNumber,
  cleanHeanders,
};
