import { Card, CardContent, Grid, Paper, Typography } from "@mui/material";

import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import UserForm from "./UserForm";

function CandidateUser() {
  return (
    <>
      <Typography variant="h3" gutterBottom display="inline">
        New User to have acess to Plataform
      </Typography>
      <Grid container>
        <Grid item xs={16}>
          <Card>
            <CardContent>
              <UserForm
                user={{
                  id: null,
                  firstName: "",
                  lastName: "",
                  username: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                  companyId: "",
                  signup: true,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default CandidateUser;
