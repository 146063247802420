import { Card, CardContent, Grid, Paper, Typography } from "@mui/material";

import UserFormComponent from "./UserForm";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import UserForm from "./UserForm";
import useAuth from "../../hooks/useAuth";

function CreateUser() {
  const { user } = useAuth();
  return (
    <>
      <OlogaPageHeader
        title="Add User"
        items={[{ label: "Users", url: "/users" }]}
      />
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <UserForm
                user={{
                  id: null,
                  firstName: "",
                  lastName: "",
                  username: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                  companyId:
                    user?.roleName === "ROLE_VENDOR" ||
                    user?.roleName === "ROLE_VENDOR_SUPERVISOR"
                      ? user?.vendorId
                      : "",
                  signup: false,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateUser;
