import {
  Logout,
  ProductionQuantityLimits,
  AccountBalance,
  Addchart,
} from "@mui/icons-material";
import { SidebarItemsType } from "../../types/sidebar";

import {
  PieChart,
  Calendar,
  CreditCard,
  Layers,
  Gift,
  Users,
  Settings,
  LogOut,
} from "react-feather";

const allRoles = [
  "ROLE_ADMIN",
  "ROLE_OPERATOR",
  "ROLE_CANDIDATE",
  "ROLE_VENDOR_SUPERVISOR",
  "ROLE_MERCHANT",
  "ROLE_MERCHANT_REORDER",
];

const coreRoles = ["ROLE_ADMIN", "ROLE_OPERATOR"];
const vendorTeamRoles = ["ROLE_VENDOR", "ROLE_VENDOR_SUPERVISOR"];

const pagesSection = [
  // {
  //   href: "/dashboard",
  //   icon: PieChart,
  //   title: "Dashboard",
  //   roles: [...coreRoles],
  // },
  // {
  //   href: "/events",
  //   icon: Calendar,
  //   title: "Events",
  //   roles: ["ROLE_ADMIN", "ROLE_PROMOTER", ...vendorTeamRoles],
  //   children: [
  //     {
  //       href: "/events/add-event",
  //       title: "Add New",
  //       roles: ["ROLE_ADMIN", "ROLE_PROMOTER"],
  //     },
  //     {
  //       href: "/events",
  //       title: "Search",
  //       roles: ["ROLE_ADMIN", "ROLE_PROMOTER", ...vendorTeamRoles],
  //     },
  //     // {
  //     //   href: "/events/merchant-allocation",
  //     //   title: "Merchants",
  //     //   roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //     // },
  //     // {
  //     //   href: "/events/stations",
  //     //   title: "Stations",
  //     //   roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //     // },

  //     {
  //       href: "/events/venues",
  //       title: "Venues",
  //       roles: ["ROLE_ADMIN", "ROLE_PROMOTER"],
  //     },
  //     {
  //       href: "/events/country-tours",
  //       title: "Country Tours",
  //       roles: ["ROLE_ADMIN", "ROLE_PROMOTER"],
  //     },
  //     {
  //       href: "/events/world-tours",
  //       title: "World Tours",
  //       roles: ["ROLE_ADMIN", "ROLE_PROMOTER"],
  //     },
  //     {
  //       href: "/events/vendors",
  //       title: "Vendors",
  //       roles: ["ROLE_ADMIN", "ROLE_PROMOTER"],
  //     },
  //     {
  //       href: "/events/promoters",
  //       title: "Promoters",
  //       roles: ["ROLE_ADMIN"],
  //     },
  //   ],
  // },
  // {
  //   href: "/forms",
  //   icon: Gift,
  //   title: "Forms",
  //   roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //   children: [
  //     {
  //       href: "/forms/create",
  //       title: "New Form",
  //       roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //     },
  //     {
  //       href: "/forms/search",
  //       title: "Search",
  //       roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //     },
  //     // {
  //     //   href: "/forms/multiple-allocation",
  //     //   title: "Allocations",
  //     //   roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //     // },
  //     // {
  //     //   href: "/forms/import",
  //     //   title: "IQRetail Sync",
  //     //   roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //     // },
  //   ],
  // },
  {
    href: "/assessment-candidate",
    icon: Gift,
    title: "Menu",
    roles: ["ROLE_CANDIDATE"],
    children: [
      {
        href: "/assessment-candidate/options",
        title: "Principal",
        roles: ["ROLE_CANDIDATE"],
      },
      // {
      //   href: "/assessments/search",
      //   title: "Search",
      //   roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      // },
    ],
  },
  {
    href: "/assessment-admin",
    icon: Gift,
    title: "Menu",
    roles: ["ROLE_ADMIN", "ROLE_OPERATOR"],
    children: [
      {
        href: "/assessment-admin/options",
        title: "Principal",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/candidates/info-create",
        title: "Questionário de Recolha de Informação dos Candidato",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/search",
        title: "Lista dos Candidaturas",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        // href: "/assessment-admin/options",
        title: "Avaliação Legal e de Compliance",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/tool/matchmaking",
        title: "Matchmaking",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/gap-assessment",
        title: "Gap Assessment",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/action-plan",
        title: "Plano de Acção",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        // href: "/assessment-admin/options",
        title: "Mapeamento de Parceiros",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        // href: "/assessment-admin/options",
        title: "Log de Projectos",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        // href: "/assessment-admin/options",
        title: "Log de Issues e Riscos",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        // href: "/assessment-admin/options",
        title: "Log de Issues e Riscos (OSS)",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
    ],
  },
  {
    href: "/assessment-admin",
    icon: Addchart,
    title: "Qualificação e Selecção",
    roles: ["ROLE_ADMIN", "ROLE_OPERATOR"],
    children: [
      {
        href: "/assessment-admin/supply-mapping",
        title: "Mapeamento de Oferta",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/demand-mapping",
        title: "Mapeamento de Demanda",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/qualification",
        title: "Listagem dos Beneficiarios Pré-Seleccionados",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/options",
        title: "Priorização dos Beneficiários",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/options",
        title: "Avaliação de Risco",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      // {
      //   href: "/assessments/search",
      //   title: "Search",
      //   roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      // },
    ],
  },
  // {
  //   href: "/assessments",
  //   icon: Gift,
  //   title: "Form Builders",
  //   roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
  //   children: [
  //     {
  //       href: "/assessments/create",
  //       title: "Add New",
  //       roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
  //     },
  //     {
  //       href: "/assessments/search",
  //       title: "Search",
  //       roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
  //     },
  //   ],
  // },
  // {
  //   href: "/candidates",
  //   icon: Gift,
  //   title: "Candidate",
  //   roles: [
  //     "ROLE_ADMIN",
  //     "ROLE_OPERATOR",
  //     "ROLE_CANDIDATE",
  //     ...vendorTeamRoles,
  //   ],
  //   children: [
  //     /*     {
  //       href: "/candidates/create",
  //       title: "Add New",
  //       roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
  //     }, */
  //     {
  //       href: "/candidates/search",
  //       title: "Search",
  //       roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
  //     },
  //     {
  //       href: "/candidates/view",
  //       title: "View Details",
  //       roles: ["ROLE_CANDIDATE", ...vendorTeamRoles],
  //     },
  //     {
  //       href: "/candidates/progress",
  //       title: "Progression",
  //       roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
  //     },
  //   ],
  // },
  // {
  //   href: "/tools",
  //   icon: Gift,
  //   title: "Tools",
  //   roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //   children: [
  //     {
  //       href: "/tools/create",
  //       title: "Add New",
  //       roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //     },
  //     {
  //       href: "/tools/search",
  //       title: "Search",
  //       roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //     },
  //   ],
  // },
  // {
  //   href: "/stock",
  //   icon: ProductionQuantityLimits,
  //   title: "Stock",
  //   roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //   children: [
  //     {
  //       href: "/stock/list",
  //       title: "List",
  //       roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //     },
  //     {
  //       href: "/stock/transfer",
  //       title: "Transfer",
  //       roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //     },
  //     {
  //       href: "/stock/reconcile",
  //       title: "Reconcile",
  //       roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //     },
  //   ],
  // },
  // {
  //   href: "/finance",
  //   icon: AccountBalance,
  //   title: "Finance",
  //   roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //   children: [
  //     {
  //       href: "/finance/bank-statement-upload",
  //       title: "Bank Statement Upload",
  //       roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //     },
  //     {
  //       href: "/finance/recon",
  //       title: "Bank Reconcile",
  //       roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //     },
  //     {
  //       href: "/finance/sales",
  //       title: "Sales",
  //       roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //     },
  //     {
  //       href: "/finance/orders",
  //       title: "Orders",
  //       roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //     },
  //     {
  //       href: "/finance/commission",
  //       title: "Commission",
  //       roles: ["ROLE_ADMIN", ...vendorTeamRoles],
  //     },
  //   ],
  // },
  {
    href: "/users",
    icon: Users,
    title: "Users",
    roles: [...coreRoles],
    children: [
      {
        href: "/users/add-user",
        title: "Add New",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/users/import-users",
        title: "Import",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
      {
        href: "/users",
        title: "Search",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      // {
      //   href: "/users/profile",
      //   title: "My Profile",
      //   roles: [...allRoles],
      // },
    ],
  },
  // {
  //   href: "/Indicators/search",
  //   icon: Addchart,
  //   title: "Indicators",
  //   roles: [...allRoles],
  // },
  // {
  //   href: "/settings/search",
  //   icon: Settings,
  //   title: "Settings",
  //   roles: [...allRoles],
  // },
  {
    href: "/logout",
    icon: Logout,
    title: "Sign out",
    roles: [...allRoles],
  },
] as SidebarItemsType[];

const filterPagesByRole = (role: string) => {
  const userMenu = pagesSection.filter((p: SidebarItemsType) =>
    p.roles?.includes(role)
  );
  userMenu.forEach((menu: SidebarItemsType) => {
    menu.children = menu.children?.filter((ch) => ch.roles?.includes(role));
  });

  return userMenu;
};

const getNavItems = (role: string) => {
  return [
    {
      title: "",
      pages: role ? filterPagesByRole(role) : pagesSection,
    },
  ];
};

export default getNavItems;
