import styled from "@emotion/styled";

import { Card as MuiCard, Grid, CardContent } from "@mui/material";
import { spacing } from "@mui/system";

import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import ToolForm from "./ToolForm";
import { Description } from "@mui/icons-material";

const Card = styled(MuiCard)(spacing);

function ToolCreate() {
  return (
    <>
      <OlogaPageHeader
        title="Add Assessment"
        items={[{ label: "Assessments", url: "/assessments/search" }]}
      />

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <ToolForm
                tool={{
                  id: null,
                  name: "",
                  Description: "",
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default ToolCreate;
