import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Grid,
  Button,
  Typography,
  TextField,
  FormControl,
  Input,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
} from "@mui/material";
import { color, spacing } from "@mui/system";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import OlogaGenericCombo from "../../components/Combos/OlogaGenericCombo";
// import OlogaMultiInput from "../../components/Combos/OlogaMultiInput";
import Item from "../../components/Combos/Item";
import useAuth from "../../hooks/useAuth";
import React, { useState, useEffect, ChangeEvent } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CancelButton from "../../components/Buttons/CancelButton";
import SubmitButton from "../../components/Buttons/SubmitButton";
import axios from "../../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import ImageCompressor from "../../hooks/ImageCompressor";
import OlogaLoading from "../../components/misc/OlogaLoading";
import { Answer, AssessmentDto, Question, Tool } from "./data_mapping";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { string } from "yup";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const FormBuilder: React.FC = () => {
  const initialProductData = {
    barCode: null,
    productDescription: null,
    quantity: null,
    price: 0,
    cost: 0,
    event: null,
    vendor: null,
    imageUri: "",
    product: {
      category: null,
      brand: null,
    },
    file: null,
    sizes: [],
  };

  const { user } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [media, setMedia] = useState<FormData>();

  const [AssessmentScoreDate, setAssessmentScoreDate] = useState("");
  const [questions, setQuestions] = useState<Question[]>([]);
  const [tool, setTool] = useState<Tool>({
    id: 0,
    name: "",
    description: "",
  });

  const [productBrands, setProductBrands] = useState<Item[]>([]);
  const [candidates, setCandidates] = useState<Item[]>([]);
  const [assessmentDtos, setAssessmentDtos] = useState<AssessmentDto[]>([]);
  const map = new Map();
  const [mapDataDto, setMapDataDto] = useState<Map<number, AssessmentDto>>(
    new Map()
  );
  const [dataDTO, setDataDto] = useState<Map<string, string>>(new Map());
  const [radioValues, setRadioValues] = useState<Map<string, string>>(
    new Map()
  );
  const [dataForm, setDataForm] = useState({});

  const [productData, setProductData] = useState(initialProductData);
  const [selectedCandidate, setSelectedCandidate] = useState<any>(
    user?.roleName == "ROLE_CANDIDATE" ? user?.candidateId : 0
  );
  const [textController, setTextController] = useState({
    questionId: 0,
    answer: "",
  });

  const [textControllerState, setTextControllerState] = useState({ id: null });

  const [productDataUnChanged, setProductDataUnChanged] =
    useState(initialProductData);
  const [sizeType, setSizeType] = useState("");
  const [radio, setRadio] = useState("");
  const [assessmentDate, setAssessmentDate] = useState<any | " ">("");
  const [submitted, setSubmitted] = useState(false);
  const [isValueChanged, setIsValueChanged] = useState<boolean>(false);
  const [previousValue, setPreviousValue] = useState();
  const [currentValue, setCurrentValue] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [showCandidates, setShowCandidate] = useState(false);
  const [selectedImage, setSelectedImage] = useState<
    string | ArrayBuffer | null
  >(null);
  const [assessmentId, setAssessmentID] = useState(0);
  const [assessmentAnswers, setAssessmentAnswers] = useState([
    {
      id: null,
      updated: false,
      score: null,
      name: "",
      description: "",
      question: {
        id: null,
        updated: false,
        name: "",
        description: "",
        category: {
          id: null,
          updated: false,
          code: null,
          type: "",
          description: "",
          parentId: null,
          state: null,
          vendorId: null,
        },
        type: {
          id: null,
          updated: false,
          code: "",
          type: "",
          description: "",
          parentId: null,
          state: null,
          vendorId: null,
        },
        tool: {
          id: null,
          updated: false,
          name: "",
          description: "",
          assessment: {
            id: null,
            updated: false,
            name: "",
          },
        },
      },
    },
  ]);
  const [assessmentDto, setAssessmentDto] = useState({});
  const [answer, setAnswer] = useState({});

  var assementData: Array<AssessmentDto> = Array();

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    retrieveQuestions(state.toolId);
    retrieveTool(state.toolId);
    retrieveCandidates();
    retrieveAssessmentScoreItems(state.toolId, state.id);
    if (state.candidateId) {
      const formattedDate = formatDate(state.assessmentDate);
      setAssessmentDate(dayjs(formattedDate));
    }
  }, []);

  const handleSizeTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    question: Question
  ) => {
    const { name, value } = event.target;

    if (value === "M") {
      updateVariable(productData, "quantity", 0, null, question);
    }

    setSizeType(value as any);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    question: Question
  ) => {
    setSubmitted(false);
    const { name, value } = event.target;
    updateVariable(productData, name, value, null, question);
  };

  function removeDuplicatesAnswer(data: Answer[]) {
    let unique: Answer[] = [];

    data.forEach((element) => {
      if (!unique.some((item) => item.name === element.name)) {
        unique.push(element);
      }
    });
    return unique;
  }

  function removeDuplicates(data: []) {
    let unique: any[] = [];
    data.forEach((element) => {
      if (!unique.includes(element)) {
        unique.push(element);
      }
    });
    return unique;
  }
  const handleInputChangeRadio = (
    event: React.ChangeEvent<HTMLInputElement>,
    question: Question
  ) => {
    setSubmitted(false);
    const { name, value } = event.target;
    console.log(name, value);
    updateVariable(productData, name, value, null, question);
    setRadio(value);
    radioValues.set(question.description, value);
  };
  const setAnswerDto = (question: Question, value: string) => {
    const newAssessmentDto: AssessmentDto = {
      flag: question.name,
      candidateId:
        state?.candidateId != null ? state?.candidateId : selectedCandidate,
      assessmentId: question.tool.assessment.id,
      toolId: question.tool.id,
      questionId: question.id,
      answer: {
        id: question?.answers[0]?.id,
        name: value,
        question: { id: question.id },
        score: "",
        description: "",
      },
      assessmentDate: "",
    };
    mapDataDto.set(question.id, newAssessmentDto);
    setAssessmentDto({
      assessmentScore: {
        candidateId: {
          id:
            state?.candidateId != null ? state?.candidateId : selectedCandidate,
        },
        assessment: { id: question.tool.assessment.id },
        tool: { id: question.tool.id },
      },
      answer: { name: value, question: { id: question.id } },
    });
  };

  const setByEntityBaseFlag = (question: Question, value: string) => {
    dataDTO.set("id", question.id.toString());
    dataDTO.set(question.description, value);
    const key = `${question.description}`;
    switch (tool.description.toLocaleUpperCase()) {
      case "CANDIDATE":
        setDataForm({
          key: value,
          answer: { name: value, question: { id: question.id } },
        });
        break;
      case "CONTRACT":
        setDataForm({
          key: value,
          answer: { name: value, question: { id: question.id } },
        });
        break;
      case "ASSESSMENT":
        setDataForm({
          key: value,
          answer: { name: value, question: { id: question.id } },
        });
        break;
      case "COMPANY":
        setDataForm({
          key: value,
          answer: { name: value, question: { id: question.id } },
        });
        break;
      case "SINGULAR":
        setDataForm({
          key: value,
          answer: { name: value, question: { id: question.id } },
        });
        break;
      case "QUALIFICATIONLEVEL":
        setDataForm({
          key: value,
          answer: { name: value, question: { id: question.id } },
        });
        break;
      default:
        break;
    }
    setAnswer({
      name: value,
      question: { id: question.id },
    });
  };
  const handleSelectChange = (event: Item, question: Question) => {
    updateVariable(productData, event.name, event, null, question);
  };
  const handleSelectChangeCandidate = (candidate: Item) => {
    // updateVariable(productData, event.name, event, null, question);
    setSelectedCandidate(candidate.id);
  };
  const handleMultiSelectChange = (
    componentName: string,
    selected: Item[],
    e: any,
    question: Question
  ) => {
    updateVariable(productData, componentName, selected, e, question);
  };

  const handleImageUpload = async (
    event: ChangeEvent<HTMLInputElement>,
    question: Question
  ) => {
    const fileBytes = event.target.files?.[0]; // Get the first selected file

    if (fileBytes) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target?.result || null);
      };
      reader.readAsDataURL(fileBytes as Blob);
    }

    const formData = await ImageCompressor(
      fileBytes as Blob,
      fileBytes?.name as string
    );

    setMedia(formData);
    updateVariable(productData, "file", formData, null, question);
  };

  const updateVariable = (
    object: any,
    propertyName: string,
    newValue: any,
    e: any,
    question: Question
  ) => {
    const propertyPath = propertyName.split(".");
    let currentObject: any = { ...object };
    updateCurrentAndPreviousValues(object, propertyName, newValue, e);

    if (propertyPath.length > 1) {
      for (let i = 0; i < propertyPath.length - 1; i++) {
        currentObject = currentObject[propertyPath[i]];
      }
      currentObject[propertyPath[propertyPath.length - 1]] = newValue;
      currentObject["updated"] = 1;
      setProductData({ ...object });
      if (question.type.code.toLowerCase() == "dropdown") {
        newValue = newValue["name"];
      }
      setAnswerDto(question, newValue);
      setByEntityBaseFlag(question, newValue);
    } else if (propertyPath.length == 1) {
      currentObject[propertyName] = newValue;
      currentObject["updated"] = 1;
      setProductData({ ...currentObject });
      if (question.type.code.toLowerCase() == "dropdown") {
        newValue = newValue["name"];
      }
      setAnswerDto(question, newValue);
      setByEntityBaseFlag(question, newValue);
    }
  };

  const updateCurrentAndPreviousValues = (
    object: any,
    propertyName: string,
    newValue: any,
    e: any
  ) => {
    if (propertyName == "quantity" && state?.eventProductId) {
      setIsValueChanged(true);
      setPreviousValue((productDataUnChanged as any)[propertyName]);
      setCurrentValue(newValue);
    }
    if (propertyName == "sizes" && state?.eventProductId) {
      setIsValueChanged(true);
      const oldSize = productDataUnChanged.sizes.filter(
        (a: any) => a.id == e.target.id.replace("q-", "")
      )[0];
      setPreviousValue((oldSize as any).quantity);

      const newSize = newValue.filter(
        (b: any) => b.id == e.target.id.replace("q-", "")
      )[0];
      setCurrentValue((newSize as any).quantity);
    }
  };

  const retrieveQuestions = async (toolId: number) => {
    const response = await axios.get(`questions/${toolId}`);
    setQuestions(
      response.data.map((e: any) => {
        const answers =
          e?.answers != null && e?.answers.length > 0
            ? removeDuplicatesAnswer(e?.answers)
            : [];

        return {
          id: e?.id,
          name: e?.name,
          description: e?.description,
          category: e?.category,
          type: e?.type,
          tool: e?.tool,
          answers: answers,
        };
      })
    );
  };

  const retrieveAssessemntScore = async (id: number) => {
    const response = await axios.get(`assessments/AssessmentScore/${id}`);
  };

  const retrieveTool = async (toolId: number) => {
    const response = await axios.get(`tools/${toolId}`);
    setTool(response.data);
    setAssessmentID(response.data.assessment.id);
  };

  const retrieveAssessmentScoreItems = async (
    toolId: number,
    assessmentScoreId: number
  ) => {
    if (state.id) {
      const response = await axios.get(
        `assessments/getAssessmentScoreItems/${toolId}/${assessmentScoreId}`
      );

      setAssessmentAnswers(response.data);
    }
  };

  const retrieveCandidates = async () => {
    const response = await axios.get(`candidate`);

    setCandidates(
      response.data.map((e: any) => {
        return {
          id: e.id,
          description: e.firstName,
          type: e.firstName,
          name: e.firstName,
          value: e.id,
        };
      })
    );
  };

  // Function to handle form submission
  const handleSubmit = async (event: React.FormEvent) => {
    var dto: any = {};

    var response;

    try {
      var dtoAssessScoreItems: any = [];

      dataDTO.forEach((value, key) => {
        dto[key] = value;
      });
      mapDataDto.forEach((value, key) => {
        value.assessmentDate = assessmentDate;
        dtoAssessScoreItems.push(value);
        dto[key] = value;
      });
      dto["assessmentDate"] = assessmentDate;
      switch (tool.description.toUpperCase()) {
        case "CONTRACT":
          dto["assessmentsDtos"] = dtoAssessScoreItems;
          response = await axios.post("assessments/contract/", dto);
          break;
        case "ASSESSMENT":
          response = await axios.post(
            "assessments/assessmentScore",
            dtoAssessScoreItems as []
          );
          break;
        case "CANDIDATE":
          switch (tool.name.toLowerCase()) {
            case "user candidate":
              dto["assessmentsDtos"] = dtoAssessScoreItems;
              response = await axios.post("assessments/user-candidate/", dto);

              break;
            case "pre user company":
              dto["assessmentsDtos"] = dtoAssessScoreItems;
              response = await axios.post("assessments/user-company/", dto);

              break;
            default:
              response = await axios.post(
                "assessments/assessmentScore",
                dtoAssessScoreItems as []
              );
              break;
          }
          break;
        case "COMPANY":
          switch (tool.name.toLowerCase()) {
            case "user candidate":
              dto["assessmentsDtos"] = dtoAssessScoreItems;
              response = await axios.post("assessments/user-candidate/", dto);

              break;
            case "pre user company":
              dto["assessmentsDtos"] = dtoAssessScoreItems;
              response = await axios.post("assessments/user-company/", dto);

              break;
            default:
              response = await axios.post(
                "assessments/assessmentScore",
                dtoAssessScoreItems as []
              );
              break;
          }
          break;

        default:
          response = await axios.post(
            "assessments/assessmentScore",
            dtoAssessScoreItems as []
          );
          break;
      }
      const result = Array.from(mapDataDto.values()).map((value) => ({
        assessmentId: assessmentId,
        toolId: value.toolId,
        questionId: value.questionId,
        answer: {
          question: { id: value.questionId },
          name: value.answer.name,
        },
        candidateId:
          state?.candidateId != null ? state?.candidateId : selectedCandidate,
      }));
      if (assessmentAnswers.length <= 0) {
        toast.success("Criado");
      } else {
        toast.success("Actualizado");
      }

      navigate(-1);
    } catch (error) {
      toast.error("Some Error Occur when submit");
    }

    setShowLoading(false);
  };

  const hasValidationErrors = () =>
    !productData.productDescription ||
    !productData.cost ||
    isNaN(Number(productData.cost)) ||
    !productData.price ||
    isNaN(Number(productData.price));

  return (
    <React.Fragment>
      <OlogaPageHeader
        title={state?.toolDesc}
        items={[{ label: "Tool", url: "/assessments/search" }]}
      />
      <Card mb={12}>
        <OlogaLoading open={showLoading} />
        <CardContent>
          <Grid container spacing={3}>
            {tool.description != "CANDIDATE" ? (
              <Grid item xs={3} md={6}>
                <p></p>
                {state?.candidateId != null ||
                user?.roleName == "ROLE_CANDIDATE" ? (
                  ""
                ) : (
                  <OlogaGenericCombo
                    label={"Candidate"}
                    name="candidateId"
                    items={candidates}
                    value={selectedCandidate || ""}
                    onChange={handleSelectChangeCandidate}
                  />
                )}
              </Grid>
            ) : (
              ""
            )}
            <Grid
              item
              md={
                state?.candidateId != null || user?.roleName == "ROLE_CANDIDATE"
                  ? 12
                  : 6
              }
              my={2}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Assessment Date"
                  format="DD/MM/YYYY"
                  value={dayjs(state.assessmentDate)}
                  onChange={(date) => {
                    setAssessmentDate(
                      date ? dayjs(date.format("DD/MM/YYYY"), "DD/MM/YYYY") : ""
                    );
                    // setFieldValue(
                    //   "assessmentDate",
                    //   date ? dayjs(date, "DD/MM/YYYY") : ""
                    // )
                  }}
                  slotProps={{
                    textField: {
                      name: "assessmentDate",
                      fullWidth: true,
                      variant: "outlined",
                      // error: touched && Boolean(errors.startDate),
                      helperText: "",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <br />
          <br />
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} marginBottom={3}>
              {questions.map((question: Question) => {
                const matchingAnswers = assessmentAnswers.filter(
                  (a) => a?.question?.id === question.id
                );
                switch (question.type.code.toLowerCase()) {
                  case "text":
                    return (
                      <Grid item xs={6} key={question.id}>
                        <p>{question.name}</p>
                        {matchingAnswers.length > 0 ? (
                          <TextField
                            key={question.id}
                            fullWidth
                            name={question.description}
                            value={
                              matchingAnswers.length > 0
                                ? matchingAnswers[0]?.description
                                : ""
                            }
                            variant="outlined"
                            onChange={(valueInput) => {
                              const newText = {
                                questionId: question.id,
                                answer: valueInput.target.value,
                              };

                              if (matchingAnswers.length > 0) {
                                matchingAnswers[0].description =
                                  valueInput.target.value;
                                matchingAnswers[0] = matchingAnswers[0];
                                const answer = {
                                  id: matchingAnswers[0].id,
                                };
                              }

                              setTextController((prev) => ({
                                ...prev,
                                newText,
                              }));
                              handleInputChange(
                                valueInput as React.ChangeEvent<HTMLTextAreaElement>,
                                question
                              );
                            }}
                            required
                            error={submitted && !question.name}
                            helperText={
                              submitted &&
                              !question.name &&
                              "This field is required"
                            }
                          />
                        ) : (
                          <TextField
                            key={question.id}
                            fullWidth
                            name={question.description}
                            /*   value={
                            matchingAnswers.length > 0
                              ? matchingAnswers[0]?.description
                              : ""
                          } */
                            variant="outlined"
                            onChange={(valueInput) => {
                              const newText = {
                                questionId: question.id,
                                answer: valueInput.target.value,
                              };

                              if (matchingAnswers.length > 0) {
                                matchingAnswers[0].description =
                                  valueInput.target.value;
                                matchingAnswers[0] = matchingAnswers[0];
                              }

                              setTextController((prev) => ({
                                ...prev,
                                newText,
                              }));
                              handleInputChange(
                                valueInput as React.ChangeEvent<HTMLTextAreaElement>,
                                question
                              );
                            }}
                            required
                            error={submitted && !question.name}
                            helperText={
                              submitted &&
                              !question.name &&
                              "This field is required"
                            }
                          />
                        )}
                      </Grid>
                    );
                  case "number":
                    return (
                      <Grid item xs={6} key={question.id}>
                        <p>{question.name}</p>

                        <TextField
                          key={question.id}
                          fullWidth
                          name={question.description}
                          variant="outlined"
                          onChange={(valueInput) => {
                            const newText = {
                              questionId: question.id,
                              answer: valueInput.target.value,
                            };
                            setTextController((prev) => ({
                              ...prev,
                              newText,
                            }));
                            handleInputChange(
                              valueInput as React.ChangeEvent<HTMLTextAreaElement>,
                              question
                            );
                          }}
                          required
                          error={submitted && !productData.productDescription}
                          helperText={
                            submitted &&
                            !productData.productDescription &&
                            "This field is required"
                          }
                        />
                      </Grid>
                    );
                  case "dropdown":
                    return (
                      <Grid item xs={5} key={question.id} md={6}>
                        <p>{question.name}</p>

                        <OlogaGenericCombo
                          key={question.id}
                          label={question.name}
                          name={question.description}
                          items={question.answers}
                          value={
                            matchingAnswers.length > 0
                              ? matchingAnswers[0].description
                              : ""
                          }
                          onChange={(valueInput: Item) =>
                            handleSelectChange(valueInput, question)
                          }
                          required={true}
                        />
                      </Grid>
                    );
                  case "textdrop":
                    return (
                      <>
                        <br />
                        <Grid item xs={6} key={question.id}>
                          <p>{question.name}</p>

                          <TextField
                            key={question.id}
                            fullWidth
                            name={question.description}
                            value={
                              matchingAnswers.length > 0
                                ? matchingAnswers[0]?.description
                                : ""
                            }
                            variant="outlined"
                            onChange={(valueInput) => {
                              const newText = {
                                questionId: question.id,
                                answer: valueInput.target.value,
                              };

                              if (matchingAnswers.length > 0) {
                                matchingAnswers[0].description =
                                  valueInput.target.value;
                                matchingAnswers[0] = matchingAnswers[0];
                              }

                              setTextController((prev) => ({
                                ...prev,
                                newText,
                              }));
                              handleInputChange(
                                valueInput as React.ChangeEvent<HTMLTextAreaElement>,
                                question
                              );
                            }}
                            required
                            error={submitted && !question.name}
                            helperText={
                              submitted &&
                              !question.name &&
                              "This field is required"
                            }
                          />
                        </Grid>
                        <Grid item xs={6} key={question.id}>
                          <p>{question.name}</p>
                          <OlogaGenericCombo
                            key={question.id}
                            label={question.name}
                            name={question.description}
                            items={question.answers}
                            value={
                              matchingAnswers.length > 0
                                ? matchingAnswers[0].description
                                : ""
                            }
                            onChange={(valueInput: Item) =>
                              handleSelectChange(valueInput, question)
                            }
                            required={true}
                          />
                        </Grid>
                      </>
                    );
                  case "radio":
                    return (
                      <Grid
                        item
                        xs={6}
                        marginBottom={3}
                        key={`${question.id}-${question.description}`}
                      >
                        <p>{question.name}</p>

                        <RadioGroup
                          key={`${question.id}-${question.description}`}
                          aria-label="options"
                          name={question.name}
                          value={radioValues.get(question.description)}
                          onChange={(valueInput) =>
                            handleInputChangeRadio(valueInput, question)
                          }
                        >
                          {removeDuplicatesAnswer(question.answers).map(
                            (item) => (
                              <FormControlLabel
                                key={`${question.id}-${question.description}`}
                                value={item["name"]}
                                control={<Radio />}
                                label={item["name"]}
                              />
                            )
                          )}
                        </RadioGroup>
                      </Grid>
                    );
                  case "button":
                    return (
                      <Grid item xs={6} key={question.id}>
                        <Grid item className="form-buttons">
                          <SubmitButton
                            label={"Save and Create New"}
                            onClick={() => {}}
                          />
                        </Grid>
                      </Grid>
                    );
                  case "filebutton":
                    return (
                      <Grid item xs={12} key={question.id}>
                        <h3>Upload Image</h3>
                        <FormControl fullWidth key={question.id}>
                          <Input
                            type="file"
                            //accept="image/*"
                            onChange={(
                              valueInput: ChangeEvent<HTMLInputElement>
                            ) => handleImageUpload(valueInput, question)}
                          />
                        </FormControl>
                        {(selectedImage || productData.imageUri) && (
                          <div>
                            <h2>Uploaded Image:</h2>
                            <img
                              src={
                                selectedImage
                                  ? (selectedImage as string)
                                  : productData.imageUri
                              }
                              width="15%"
                              height="auto"
                            ></img>
                          </div>
                        )}
                      </Grid>
                    );
                }
              })}
              <Grid
                container
                marginTop={5}
                spacing={3}
                mt={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {" "}
                <Grid item className="form-buttons">
                  <SubmitButton label={"Save"} onClick={handleSubmit} />
                </Grid>
                <Grid item marginRight={2}>
                  <SubmitButton
                    label={"Cancel"}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default FormBuilder;
