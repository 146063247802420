import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Grid,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SettingsModal from "./SettingsModal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import http from "../../utils/axios";
import { number } from "yup";

const Card = styled(MuiCard)(spacing);

export default function SettingsMSettingsListodal(props: any) {
  const [value, setValue] = useState("1");
  const [settingsIdsInUse, setSettingsIdsInUse] = useState<number[]>([]);
  const [renderOnModal, setRenderOnModal] = useState(false);
  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
    console.log("event", event);
    console.log("newValue", newValue);
  };
  const [trigger, setTrigger] = useState(0);
  const handleCloseSettingModal = () => {
    setTrigger((trigger) => trigger + 1);
  };

  const settingsIdsInUseMethod = async () => {
    const result = await http.get("/simple-entity/settingsIsUsed");
    setSettingsIdsInUse(result?.data);
  };

  const isIdUsed = (number: number) => {
    return settingsIdsInUse.some((num) => num == number);
  };

  useEffect(() => {
    settingsIdsInUseMethod();
    if (props.renderFormat == "hasProductCategoryModal") {
      setValue("1");
      setRenderOnModal(true);
    }
    if (props.renderFormat == "hasProductBrandModal") {
      setValue("3");
      setRenderOnModal(true);
    }
  }, [props, trigger]);
  const columns: GridColDef[] = [
    {
      field: "description",
      headerName: "Description",
      minWidth: 200,
      editable: false,
    },
    {
      field: "typeDto",
      headerName: "Type",
      width: 200,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Ações",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <>
          {props.renderFormat == "hasProductCategoryModal" ||
          props.renderFormat == "hasProductBrandModal" ? (
            <></>
          ) : (
            <GridActionsCellItem
              icon={<VisibilityOutlinedIcon />}
              onClick={() => {
                navigate(`/settings/edit`, {
                  state: {
                    settingObj: {
                      ...params.row,
                    },
                  },
                });
              }}
              label="Edit"
            />
          )}
          {isIdUsed(params?.row?.id) ? (
            <div style={deleteStyle}></div>
          ) : (
            <SettingsModal
              settingObj={params}
              btnType="DeleteIcon"
              title={"Delete Setting: " + params?.row?.typeDto}
              type="PRODUCT_SUB_CATEGORY"
              onClosemodal={handleCloseSettingModal}
            />
          )}
        </>,
      ],
    },
    {
      field: "hide",
      headerName: "Visible",
      flex: 0.3,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={params.row.state}
                onChange={async () => {
                  try {
                    const response = await http.post(
                      `/simple-entity/update-state/${params.id}`
                    );
                    toast.success("Setting visible updated " + response.data);
                    handleCloseSettingModal();
                  } catch (error) {
                    toast.error((error as any).message);
                  }
                }}
              />
            }
            label=""
          />
        </FormGroup>,
      ],
    },
  ];

  const navigate = useNavigate();
  const deleteStyle: React.CSSProperties = {
    marginLeft: "28px",
  };
  const textStyle: React.CSSProperties = {
    textAlign: "right",
  };
  const textStyleRight: React.CSSProperties = {
    textAlign: "left",
  };
  const btnStyl = {
    height: "50px",
    textAlign: "left",
  };

  return (
    <React.Fragment>
      {props.renderFormat == "hasProductCategoryModal" ||
      props.renderFormat == "hasProductBrandModal" ? (
        <></>
      ) : (
        <OlogaPageHeader title="Settings" />
      )}
      {/* <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          {props.renderFormat == "hasProductCategoryModal" ||
          props.renderFormat == "hasProductBrandModal" ? (
            <></>
          ) : (
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="">
                <Tab label="Product category" value="1" />
                <Tab label="Product brand" value="3" />
                <Tab label="Product color" value="4" />
                <Tab label="Product size" value="5" />
                <Tab label="Type" value="6" />
                <Tab label="Location" value="7" />
                <Tab label="Country" value="8" />
              </TabList>
            </Box>
          )}

          <TabPanel value="1">
            <Grid container>
              <Grid item xs={12}>
                <Card>
                  <Grid container spacing={2} marginBottom={3}>
                    <Grid item xs={12}>
                      <Grid item className="form-buttons">
                        <div style={textStyle}>
                          <SettingsModal
                            btnName="+ New Product category"
                            title="New Product category"
                            type="PRODUCT_SUB_CATEGORY"
                            onClosemodal={handleCloseSettingModal}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <OlogaDataGrid
                    url={"simple-entity/page/PRODUCT_SUB_CATEGORY"}
                    columns={columns}
                    triggerUseEffect={trigger}
                    renderOnModal={renderOnModal}
                    pageSize={7}
                  />
                </Card>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="3">
            <Grid container>
              <Grid item xs={12}>
                <Card>
                  <Grid container spacing={2} marginBottom={3}>
                    <Grid item xs={12}>
                      <Grid item className="form-buttons">
                        <div style={textStyle}>
                          <SettingsModal
                            btnName="+ New Product brand"
                            title="New Product brand"
                            type="PRODUCT_BRAND"
                            onClosemodal={handleCloseSettingModal}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <OlogaDataGrid
                    url={"simple-entity/page/PRODUCT_BRAND"}
                    columns={columns}
                    triggerUseEffect={trigger}
                    renderOnModal={renderOnModal}
                    pageSize={7}
                  />
                </Card>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="4">
            <Grid container>
              <Grid item xs={12}>
                <Card>
                  <Grid container spacing={2} marginBottom={3}>
                    <Grid item xs={12}>
                      <div style={textStyle}>
                        <SettingsModal
                          btnName="+ New Product color"
                          title="New Product color"
                          type="PRODUCT_COLOR"
                          onClosemodal={handleCloseSettingModal}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <OlogaDataGrid
                    url={"simple-entity/page/PRODUCT_COLOR"}
                    columns={columns}
                    triggerUseEffect={trigger}
                  />
                </Card>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="5">
            <Grid container>
              <Grid item xs={12}>
                <Card>
                  <Grid container spacing={2} marginBottom={3}>
                    <Grid item xs={12}>
                      <div style={textStyle}>
                        <SettingsModal
                          btnName="+ New Type"
                          title="New Type"
                          type="PRODUCT_SIZE"
                          onClosemodal={handleCloseSettingModal}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <OlogaDataGrid
                    url={"simple-entity/page/PRODUCT_SIZE"}
                    columns={columns}
                    triggerUseEffect={trigger}
                  />
                </Card>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="6">
            <Grid container>
              <Grid item xs={12}>
                <Card>
                  <Grid container spacing={2} marginBottom={3}>
                    <Grid item xs={12}>
                      <div style={textStyle}>
                        <SettingsModal
                          btnName="+ New Product size"
                          title="New Product size"
                          type="TYPE"
                          onClosemodal={handleCloseSettingModal}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <OlogaDataGrid
                    url={"simple-entity/page/TYPE"}
                    columns={columns}
                    triggerUseEffect={trigger}
                  />
                </Card>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="7">
            <Grid container>
              <Grid item xs={12}>
                <Card>
                  <Grid container spacing={2} marginBottom={3}>
                    <Grid item xs={12}>
                      <div style={textStyle}>
                        <SettingsModal
                          btnName="+ New Location"
                          title="New Location"
                          type="LOCATION"
                          onClosemodal={handleCloseSettingModal}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <OlogaDataGrid
                    url={"simple-entity/page/LOCATION"}
                    columns={columns}
                    triggerUseEffect={trigger}
                  />
                </Card>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="8">
            <Grid container>
              <Grid item xs={12}>
                <Card>
                  <Grid container spacing={2} marginBottom={3}>
                    <Grid item xs={12}>
                      <div style={textStyle}>
                        <SettingsModal
                          btnName="+ New Country"
                          title="New Country"
                          type="COUNTRY"
                          onClosemodal={handleCloseSettingModal}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <OlogaDataGrid
                    url={"simple-entity/page/COUNTRY"}
                    columns={columns}
                    triggerUseEffect={trigger}
                  />
                </Card>
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Box> */}
    </React.Fragment>
  );
}
