import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Button,
  CardContent,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import http from "../../utils/axios";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import OlogaGenericCombo from "../../components/Combos/OlogaGenericCombo";
import SubmitButton from "../../components/Buttons/SubmitButton";
import Item from "../../components/Combos/Item";
import { VisibilityOutlined } from "@mui/icons-material";
import OlogaLoading from "../../components/misc/OlogaLoading";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function BankRecon() {
  const [event, setEvent] = useState();
  const [stats, setStats] = useState([]);
  const [events, setEvents] = useState([{ id: 0, name: "" }]);
  const [uri, setUri] = useState("/transactions/bank-reconcile/q?");
  const [showLoading, setShowLoading] = useState(false);
  const [eventId, setEventId] = useState(0);
  const [show, setShow] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  function EmptyCard() {
    return <Card mb={6}></Card>;
  }

  const handleChange = (event: Item) => {
    setShowLoading(true);
    setEvent(event as any);
    setEventId(Number(event.id));
    getData(Number(event.id));
    setUri("/transactions/bank-reconcile/q?eventId=" + event.id + "&type=");
    setShowLoading(false);
  };

  const getData = async (eventIdParam: number) => {
    try {
      setShow(false);
      const response = await http.get(
        `/banktx/reconcile-stats/${eventIdParam}`
      );
      setStats(response.data);
      setShow(true);
    } catch (error) {}
  };

  const retriveEvents = async () => {
    const response = await http.get(`/events/names`);
    setEvents(
      response.data.map((r: Item) => {
        return {
          id: r.id,
          description: r.name,
          type: r.type,
          name: "event",
        };
      })
    );
  };

  useEffect(() => {
    retriveEvents();
    if (state && state.eventId) {
      setEventId(state.eventId);
      getData(state.eventId);
    }
  }, []);

  return (
    <React.Fragment>
      <OlogaPageHeader title="Bank Reconcile" />

      <Card mb={12}>
        <OlogaLoading open={showLoading} />
        <CardContent>
          <form>
            <Grid container spacing={2} marginBottom={3}>
              <Grid item xs={6}>
                <OlogaGenericCombo
                  label="Event"
                  name="event"
                  items={events}
                  value={event || ""}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Divider my={6} />
            {show ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>RECONCILE SCENARIO</TableCell>
                      <TableCell>PROCESSED TRANSACTIONS</TableCell>
                      <TableCell>PROCESSED AMOUNT</TableCell>
                      <TableCell>ACTIONS</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        Transactions Reconciled Successfully
                      </TableCell>
                      <TableCell>{(stats as any).ok}</TableCell>
                      <TableCell>
                        {(stats as any).okAmount?.toLocaleString("en-ZA", {
                          style: "currency",
                          currency: "ZAR",
                        })}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            navigate("/finance/recondetail", {
                              state: {
                                scenario: "0",
                                eventId: eventId,
                              },
                            });
                          }}
                          name="Detail"
                        >
                          {<VisibilityOutlined />}
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Transactions With Same Reference and Diferente Values
                      </TableCell>
                      <TableCell>{(stats as any).notOkDiference}</TableCell>
                      <TableCell>
                        {(stats as any).notOkDiferenceAmount?.toLocaleString(
                          "en-ZA",
                          {
                            style: "currency",
                            currency: "ZAR",
                          }
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            navigate("/finance/recondetail", {
                              state: {
                                scenario: "-1",
                                eventId: eventId,
                              },
                            });
                          }}
                          name="Detail"
                        >
                          {<VisibilityOutlined />}
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Transactions Not Registered On Pagamio but Registered on
                        the Bank (Fixable)
                      </TableCell>
                      <TableCell>
                        {(stats as any).notOkPagamioButFixable}{" "}
                      </TableCell>
                      <TableCell>
                        {(
                          stats as any
                        ).notOkPagamioAmountButFixable?.toLocaleString(
                          "en-ZA",
                          {
                            style: "currency",
                            currency: "ZAR",
                          }
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            navigate("/finance/recondetail", {
                              state: {
                                scenario: "-2",
                                eventId: eventId,
                              },
                            });
                          }}
                        >
                          {<VisibilityOutlined />}
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Transactions Not Registered On Pagamio but Registered on
                        the Bank
                      </TableCell>
                      <TableCell>{(stats as any).notOkPagamio} </TableCell>
                      <TableCell>
                        {(stats as any).notOkPagamioAmount?.toLocaleString(
                          "en-ZA",
                          {
                            style: "currency",
                            currency: "ZAR",
                          }
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            navigate("/finance/recondetail", {
                              state: {
                                scenario: "-3",
                                eventId: eventId,
                              },
                            });
                          }}
                        >
                          {<VisibilityOutlined />}
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {
                          "Transactions Not Registered On The Bank but Registered on Pagamio"
                        }
                      </TableCell>
                      <TableCell> {(stats as any).notOkBank} </TableCell>
                      <TableCell>
                        {(stats as any).notOkBankAmount?.toLocaleString(
                          "en-ZA",
                          {
                            style: "currency",
                            currency: "ZAR",
                          }
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            navigate("/finance/recondetail", {
                              state: {
                                scenario: "-4",
                                eventId: eventId,
                              },
                            });
                          }}
                          value="Detail"
                        >
                          {<VisibilityOutlined />}
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <EmptyCard />
            )}{" "}
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default BankRecon;
