import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Card, CardContent, Typography, Grid, Divider } from "@mui/material";
import http from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";

// Registrar componentes do ChartJS
ChartJS.register(ArcElement, Tooltip, Legend);

const Panel: React.FC = () => {
  const { user } = useAuth();
  const [_dataCandidaturasPorEstado, setDataCandidaturasPorEstado] =
    useState<any>({
      labels: [],
      datasets: [],
    });
  const [_dataCandidaturasPorTipo, setDataCandidaturasPorTipo] = useState<any>({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get(`/candidate/data/` + user?.id);
        const { estado, tipo } = response.data;

        // Atualizar o estado com os dados da API
        setDataCandidaturasPorEstado({
          labels: estado.labels,
          datasets: [
            {
              label: "Candidaturas por Estado",
              data: estado.data,
              backgroundColor: estado.backgroundColor,
              borderColor: estado.borderColor,
              borderWidth: 1,
            },
          ],
        });

        setDataCandidaturasPorTipo({
          labels: tipo.labels,
          datasets: [
            {
              label: "Candidaturas por Tipo",
              data: tipo.data,
              backgroundColor: tipo.backgroundColor,
              borderColor: tipo.borderColor,
              borderWidth: 1,
            },
          ],
        });
      } catch (error) {
        console.error("Erro ao buscar dados da API:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {/*   <Divider>Gráficos de Candidaturas</Divider> */}

      {/* Container Grid para os gráficos */}
      <Grid container spacing={2} style={{ marginTop: 5, marginBottom: 10 }}>
        {/* Gráfico de Candidaturas por Estado */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="div" gutterBottom>
                <Divider> Candidaturas por Estado</Divider>
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                  width: "100%",
                }}
              >
                <Pie data={_dataCandidaturasPorEstado} />
              </div>
            </CardContent>
          </Card>
        </Grid>

        {/* Gráfico de Candidaturas por Tipo */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="div" gutterBottom>
                <Divider> Candidaturas por Tipo</Divider>
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                  width: "100%",
                }}
              >
                <Pie data={_dataCandidaturasPorTipo} />
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/*
      <Card style={{ maxWidth: "100%", margin: "auto", marginTop: 20 }}>
        <CardContent>
          <OlogaBackButton />
        </CardContent>
      </Card> */}
    </>
  );
};

export default Panel;
