import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Item from "../../components/Combos/Item";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import OlogaGenericCombo from "../../components/Combos/OlogaGenericCombo";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Autocomplete,
  TextField,
} from "@mui/material";

import axios from "../../utils/axios";

import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import useLookups from "../../hooks/useLookups";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import useAuth from "../../hooks/useAuth";
import { GridActionsCellItem } from "@mui/x-data-grid";
import StockDetailModal from "./StockDetailModal";
import OlogaLoading from "../../components/misc/OlogaLoading";
import OlogaDownloadExcel from "../../components/misc/OlogaDownloadExcel";

const StockList: React.FC<any> = () => {
  const initialFormData = {
    stationVendor: null,
    stationEvent: null,
  };

  const { data: vendors } = useLookups("/companies/vendors/user");
  const [vendorEvents, setVendorEvents] = useState([]);
  const [eventProducts, setEventProducts] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedEventProductMetadata, setSelectedEventProductMetadata] =
    useState<any>({});
  const [selectedEventId, setSelectedEventId] = useState("");
  const [selectedEventName, setSelectedEventName] = useState("");
  const { user: userContext, isAdmin } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!userContext?.isAdmin) getVendorEvents(userContext?.vendorId);
  }, [userContext]);

  const getVendorEvents = async (vendorId: string) => {
    setVendorEvents([]);
    if (vendorId) {
      try {
        const response = await axios.get(`/events/names`);
        setVendorEvents(response.data);
      } catch (error) {}
    }
  };

  const exportReport = () => {
    // exportToExcel();
  };

  const headers = [
    { field: "name", headerName: "Name" },
    { field: "id", headerName: "ID" },
    { field: "stationName", headerName: "Station Name" },
    { field: "imageUri", headerName: "Image URI" },
    { field: "sizeDescription", headerName: "Size Desc" },
    { field: "stationName", headerName: "Station Name" },
    { field: "stockIn", headerName: "Stock In" },
    { field: "stockOut", headerName: "Stock Out" },
    { field: "finalManualStockCount", headerName: "Manual Stock Count" },
    { field: "finalStockCountPagamio", headerName: "Final Stock" },
    { field: "stockCountDifference", headerName: "Diference" },
  ];

  const getEventProductStock = async (eventId: string) => {
    setEventProducts([]);
    const response = await axios.get(
      `/stock/reconcile/aggregated/event/${eventId}/vendor/${userContext?.vendorId}`
    );
    setEventProducts(
      response.data.map((r: any) => {
        return {
          id: r.eventProductId,
          name: r.productDescription,
          imageUri: r.imageUri,
          stockIn: r.transferIn,
          stockOut: r.transferOut,
          closingStock: r.closingStock,
          finalStockManual: r.finalStockManual,
          difference: r.difference,
          quantitySold: r.quantitySold,
        };
      })
    );
  };

  const handleChangeVendor = async (e: Item) => {
    updateVariable(formData, "stationVendor", e);
    getVendorEvents(e.id.toString());
  };

  const handleChangeEvent = async (e: Item) => {
    updateVariable(formData, "stationEvent", e);
    if (e) {
      setSelectedEventId(e.id.toString());
      setSelectedEventName(e.name);
      getEventProductStock(e.id.toString());
    } else {
      setEventProducts([]);
    }
  };

  const updateVariable = (object: any, propertyName: string, newValue: any) => {
    const propertyPath = propertyName.split(".");
    let currentObject: any = { ...object };

    if (propertyPath.length > 1) {
      for (let i = 0; i < propertyPath.length - 1; i++) {
        currentObject = currentObject[propertyPath[i]];
      }
      currentObject[propertyPath[propertyPath.length - 1]] = newValue;
      setFormData({ ...object });
    } else if (propertyPath.length == 1) {
      currentObject[propertyName] = newValue;
      setFormData({ ...currentObject });
    }
  };

  return (
    <React.Fragment>
      <OlogaPageHeader
        title="Stock List"
        items={[{ label: "Products", url: "/products/search" }]}
      />
      <Card>
        <CardContent>
          <form>
            <Grid container spacing={2} marginBottom={3}>
              {isAdmin && (
                <Grid item xs={6}>
                  <OlogaGenericCombo
                    name="stationVendor"
                    label="Vendors"
                    items={vendors}
                    value={formData.stationVendor || ""}
                    onChange={handleChangeVendor}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <Autocomplete
                  options={vendorEvents}
                  getOptionLabel={(option: any) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Events" variant="outlined" />
                  )}
                  onChange={(event, value) => {
                    handleChangeEvent(value);
                  }}
                />
              </Grid>
            </Grid>
            {selectedEventId && (
              <Grid container spacing={2} marginBottom={3}>
                <Grid item xs={2}>
                  <OlogaDownloadExcel
                    data={eventProducts}
                    headers={headers}
                    buttonLabel="Export Stock List"
                    variant="contained"
                    fileName="Stock List"
                  />
                </Grid>
              </Grid>
            )}
            <br />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>IMAGE</TableCell>
                    <TableCell>PRODUCT NAME</TableCell>
                    <TableCell>STOCK IN</TableCell>
                    <TableCell>STOCK OUT</TableCell>
                    <TableCell>{`BALANCE (PAGAMIO)`}</TableCell>
                    <TableCell>{`FINAL STOCK (MANUAL COUNT)`}</TableCell>
                    <TableCell>DIFFERENCE</TableCell>
                    <TableCell>ACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventProducts.map(
                    ({
                      id,
                      imageUri,
                      name,
                      stockIn,
                      stockOut,
                      closingStock,
                      finalStockManual,
                      difference,
                    }) => (
                      <TableRow key={id + name + stockIn}>
                        <TableCell width={30}>
                          <img
                            src={imageUri}
                            width="30px"
                            height="auto"
                            alt="product-img"
                          />
                        </TableCell>
                        <TableCell>{name}</TableCell>
                        <TableCell> {stockIn}</TableCell>
                        <TableCell> {stockOut}</TableCell>
                        <TableCell>{closingStock}</TableCell>
                        <TableCell>{finalStockManual}</TableCell>
                        <TableCell>{difference}</TableCell>
                        <TableCell>
                          {
                            <VisibilityOutlinedIcon
                              onClick={() => {
                                setSelectedEventProductMetadata({
                                  vendorId: userContext?.vendorId,
                                  eventId: selectedEventId,
                                  eventName: selectedEventName,
                                  eventProductId: id,
                                  productName: name,
                                  imageUri: imageUri,
                                  stockIn: stockIn,
                                  stockOut: stockOut,
                                  balance: closingStock,
                                  manualStockCount: finalStockManual,
                                });
                                setOpenDetailsModal(true);
                              }}
                            />
                          }
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </form>
        </CardContent>
      </Card>
      <StockDetailModal
        open={openDetailsModal}
        onClose={() => {
          setOpenDetailsModal(false);
        }}
        stockMetadata={selectedEventProductMetadata}
      />
    </React.Fragment>
  );
};

export default StockList;
