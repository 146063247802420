import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const Terms: React.FC = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const handleAccept = () => {
    setOpen(false);
  };

  const handleReject = () => {
    navigate(-1);
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>Terms and Conditions of Use</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ maxHeight: "80%", overflowY: "auto", textAlign: "justify" }}>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            General Terms and Conditions of Use (GTCU)
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to the (supplier registration CapacitaMoz Management Tool
            website http://www.) (hereinafter « Website »). You agree to read
            and accept, without limitation or reservation, this document
            (hereinafter "General Terms and Conditions of Use" or "GTCU") by
            ticking the box provided for this purpose when you first connect to
            the Website. You also undertake to read the personal data and
            cookies charter (Insert the hyperlink to the Website personal data
            and cookies charter) available on this Website. Please note that if
            you visit other TotalEnergies group company websites, different
            general terms and conditions of use and policies related to personal
            data protection, or any other document relating to the protection of
            personal data, apply to these websites. It is recommended that you
            carefully read their legal notices.
          </Typography>
          <Typography variant="body1" paragraph>
            The companies within the group TotalEnergies company have their own
            legal existence and independent legal status. The company publishing
            this Website (hereinafter “Website Publisher”) belongs to the
            TotalEnergies group company. “TotalEnergies”, “TotalEnergies company
            group”, “Group” and “Company” generally refers to TotalEnergie SE
            and/or its subsidiaries and affiliates; a subsidiary is a company in
            which TotalEnergies SE directly or indirectly holds a majority of
            the voting rights. Likewise, the words “we”, “us” and “our” are used
            to refer to the Website Publisher or any company of the
            TotalEnergies company in general or to those who work for them.
            These expressions are also used where no useful purpose is served by
            identifying the particular company or companies. It cannot be
            inferred from these expressions that TotalEnergies SE or any of its
            subsidiaries or affiliates are involved in the business and
            management of any other company of TotalEnergies company.
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            1. Identity
          </Typography>
          <Typography variant="body1" paragraph>
            1.1. Website Publisher TotalEnergies EP Mozambique Area 1, Lda. Head
            Office: Avenida Julius Nyerere 3412, Maputo, Mozambique Company
            Type: private limited liability company Trade and Companies
            Register: Legal Entities Registry Office of Maputo Number 100004674
            Tax Number: 400162621
          </Typography>
          <Typography variant="body1" paragraph>
            1.2. Website Hosting Company XXXX
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            2. Definitions
          </Typography>
          <Typography variant="body1" paragraph>
            Terms and expressions identified by capitalization in the GTCU have
            the following meanings, whether used singular or plural: "Account":
            refers to the unique space reserved for the User, accessible at the
            end of the registration and allowing the User to use the services
            offered on the Website. "Contribution": refers to any type of
            content (including text and documents) published by a User on the
            Website. "Profile": refers to the interface made available to a User
            and accessible to other Users, including the description made by the
            User and including texts and documents. "User": refers to any person
            who has an Account and accesses the Website.
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            3. Access and registration
          </Typography>
          <Typography variant="body1" paragraph>
            GTCU have been developed to govern the Website use. In the field of
            technology and communication, they supplement the charters and other
            texts applicable within the Company: Code of conduct, internal
            regulations, charter for the use of IT resources, etc. Any User of
            the Website must respect the principles established by the GTCU
            which define a general and common framework for all to avoid
            inappropriate use of the Website. They apply to all the features of
            the Website.
          </Typography>
          <Typography variant="body1" paragraph>
            3.1 Conditions of access to the Website Each User acknowledges to
            comply with the GTCU and the laws in force, and in particular: have
            the necessary skills and means to access and use the Website;
            consent to the Website Publisher and its partners, as the case may
            be, the right to make any use of the information provided (other
            than personal data); check that the internet configuration used does
            not contain any viruses and is in perfect working order; to keep
            confidential logins and passwords that the Website Publisher may
            transmit to the User and therefore be responsible for their use and
            security. The Website Publisher reserves the right to suspend access
            to the Website in the event of fraudulent use or attempted
            fraudulent use of such access.
          </Typography>
          <Typography variant="body1" paragraph>
            3.2 Website registration procedure To use the Website, the User must
            create an Account by providing the requested information. The User
            will then receive a confirmation code confirming his/her
            registration and allowing him/her to access and use the Platform.
            The User will have to choose a password that is sufficiently secure.
            Once registered, the User will be able to log into his/her Account
            with the credentials provided during registration.
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            4. Use and Undertakings of the User
          </Typography>
          <Typography variant="body1" paragraph>
            4.1 Use of the Website On the Website, the User will have the
            possibility to: Create an Account Update his/her profile Access to
            information about potential tender opportunities
          </Typography>
          <Typography variant="body1" paragraph>
            4.2 Undertakings of the User The User acknowledges that: the GTCU
            apply to any User authorized to use the Website; the Website is
            intended for professional use; each User is identified and speaks on
            his/her behalf on the Website. The User also undertakes to: protect
            personal data in accordance with applicable law and internal
            standards. It shall refrain from any use that would be contrary to
            the regulations in force, public order and morality; provide
            accurate, complete, sincere, unambiguous information and ensure, as
            necessary, that it is updated in the event of changes; have all the
            rights and authorizations necessary to provide the information and
            content published.
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            5. Intellectual property
          </Typography>
          <Typography variant="body1" paragraph>
            5.1. Principle All information or documents (texts, animated or
            static images, sounds, photographs, know-how, quoted products)
            displayed on the Website as well as all elements created for the
            Website and its general structure, are either the property of the
            Website Publisher or the group TotalEnergies company, or are subject
            to use, reproduction and representation rights that have been
            granted to such. These information, documents and items are subject
            to laws protecting copyright insofar as they have been made
            available to the public on this Website. No license or any right
            other than to view the Website has been granted to any person with
            respect to intellectual property rights. Reproduction of Website
            documents is authorized solely as information for personal and
            private usage. Any reproduction and any use of copies made for other
            purposes is expressly prohibited and subject to the prior and
            express authorization of the Website Publisher. In all cases, the
            authorized reproduction of information displayed on the Website must
            quote the appropriate source and ownership.
          </Typography>
          <Typography variant="body1" paragraph>
            5.2. Distinguishing Marks Unless otherwise stated, company names,
            logos, products and brands quoted on the Website are the property of
            the Website Publisher or the TotalEnergies company, or are subject
            to use, reproduction or representation rights that have been granted
            to them. Reproduction of these marks is prohibited and subject to
            the prior and express authorization of the Website Publisher or
            TotalEnergies company.
          </Typography>
          <Typography variant="body1" paragraph>
            5.3. Third-Party Intellectual Property Rights Any other intellectual
            property rights and any content not created by the Website Publisher
            are the property of their respective authors or holders. Any use of
            such content requires the prior authorization of their respective
            authors or holders.
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            6. Liability and Disclaimers
          </Typography>
          <Typography variant="body1" paragraph>
            The Website Publisher cannot guarantee that the Website and the
            services will function without interruption or errors. The Website
            Publisher does not guarantee that the Website and the services will
            be free of viruses or other harmful components. The Website
            Publisher is not responsible for any damage to Users' equipment or
            any loss of data resulting from the use of the Website. The Website
            Publisher will not be liable for any indirect damages, including but
            not limited to loss of profit, loss of use or any other incidental
            damages arising from the use or inability to use the Website.
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            7. Modification of GTCU
          </Typography>
          <Typography variant="body1" paragraph>
            The Website Publisher reserves the right to modify the GTCU at any
            time. Users will be informed of any changes made, and the new GTCU
            will be made available on the Website. Users are responsible for
            regularly checking the GTCU and must comply with the latest version
            available.
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            8. Applicable Law and Jurisdiction
          </Typography>
          <Typography variant="body1" paragraph>
            The GTCU are governed by Mozambican law. In case of dispute arising
            from the use of the Website or the interpretation of the GTCU, the
            competent courts of Mozambique will have exclusive jurisdiction.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReject}>Reject</Button>
        <Button onClick={handleAccept} color="primary">
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Terms;
