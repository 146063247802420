import styled from "@emotion/styled";

import {
  Card as MuiCard,
  Grid,
  CardContent,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";

import EventForm from "./EventForm";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import OlogaTabPanel from "../../components/misc/OlogaTab";
import StationForm from "../station/StationForm";
import MerchantAllocation from "./MerchantAllocation";
import useAuth from "../../hooks/useAuth";
import { formatDateToDdMmYyyy } from "../../utils/utils";
import dayjs from "dayjs";

const Card = styled(MuiCard)(spacing);

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ManageVendorEvent() {
  const { user } = useAuth();
  const [value, setValue] = useState(0);
  const [isPromoter, setIsPromoter] = useState(
    user?.roleName === "ROLE_PROMOTER"
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { state } = useLocation();
  return (
    <>
      <OlogaPageHeader
        title={`Manage Event: ${state.event?.name} (${state.event?.cityName} ${
          state.event
            ? dayjs(state.event.startDate, "DD/MM/YYYY").format("DD/MM/YYYY")
            : ""
        })`}
        items={[{ label: "Events", url: "/events" }]}
      />

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label="Stations"
                      {...a11yProps(0)}
                      disabled={isPromoter}
                    />
                    <Tab
                      label="Merchants"
                      {...a11yProps(1)}
                      disabled={isPromoter}
                    />
                  </Tabs>
                </Box>

                <OlogaTabPanel value={value} index={0}>
                  <StationForm
                    station={{
                      id: "",
                      eventId: state.event.eventId,
                      eventName: state.event.name,
                      geoLocation: "",
                      name: "",
                      supervisorId: "",
                      supervisorName: "",
                      vendorId: user?.vendorId,
                      vendorName: "",
                      venueName: state.event.venueName,
                      submit: false,
                    }}
                    isVendor={true}
                    vendorId={user?.vendorId}
                  />
                </OlogaTabPanel>
                <OlogaTabPanel value={value} index={1}>
                  <MerchantAllocation
                    showBreadcrumb={false}
                    isVendor={true}
                    vendorId={user?.vendorId}
                    eventId={state.event.eventId}
                  />
                </OlogaTabPanel>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default ManageVendorEvent;
