import React, { useEffect, useState } from "react";
import CandidateForm from "./CandidateForm";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const CandidateUpdate: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  useEffect(() => {
    console.log("User é ", user);
  }, []);

  return (
    <div>
      <CandidateForm id={state.id} userType={user?.roleName} />
    </div>
  );
};

export default CandidateUpdate;
