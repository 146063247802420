import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";

interface DataItem {
  [key: string]: any;
}

interface ColumnHeader {
  field: string;
  headerName: string;
}

interface OlogaDownloadExcelProps {
  data: DataItem[];
  headers: ColumnHeader[];
  buttonLabel?: string;
  variant?: "text" | "outlined" | "contained";
  fileName?: string;
}

const OlogaDownloadExcel: React.FC<OlogaDownloadExcelProps> = ({
  data,
  headers,
  buttonLabel = "Export to Excel",
  variant = "text",
  fileName = "data_export",
}) => {
  const [loading, setLoading] = useState(false);

  const exportToExcel = () => {
    setLoading(true);

    const mappedData = data.map((item) => {
      const newItem: { [key: string]: any } = {};
      headers.forEach((header) => {
        newItem[header.headerName] = item[header.field];
      });
      return newItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(mappedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    // Create a link element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.xlsx`;
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    setLoading(false);
  };

  return (
    <div>
      <Button
        variant={variant}
        color="primary"
        onClick={exportToExcel}
        disabled={loading}
        startIcon={<DownloadIcon />}
      >
        {loading ? <CircularProgress size={24} /> : buttonLabel}
      </Button>
    </div>
  );
};

export default OlogaDownloadExcel;
