import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Card as MuiCard, Grid, CardContent } from "@mui/material";
import { spacing } from "@mui/system";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import http from "../../utils/axios";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React from "react";
import { toolSchema } from "../../utils/formValidation";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import OlogaSelect from "../../components/select/OlogaSelect";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { margin, padding } from "polished";
import useAuth from "../../hooks/useAuth";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
interface Rows {
  name: string;
  descrition: number;
  AssessmentId: number;
}

interface FormData {
  id: number;
  updated: boolean;
  firstName: string;
  lastName: string;
  identificationDocType: string | null;
  otherIdentificationDocType: string | null;
  identificationDocNumber: string;
  nuit: string;
  province: string | null;
  provinceId: number | null;
  district: string | null;
  districtId: number | null;
  neighborhood: string;
  street: string;
  block: string;
  houseNumber: string;
  phoneNumber: string;
  phoneNumber1: string;
  phoneNumber2: string;
  email: string;
  altContactPersonName: string;
  altContactPersonPhoneNumber: string;
  altContactPersonRelationship: string;
  name: string | null;
}

const Card = styled(MuiCard)(spacing);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface RowInterface {
  name: string;
  description: string;
  id: number;
}

interface ToolFormProps {
  tool?: any;
}

const cardTyele = {
  padding: "1%",
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CandidateViewPage() {
  const { user } = useAuth();

  const [CandidateFormData, setCandidateFormData] = useState<FormData>({
    id: 0,
    updated: false,
    firstName: "",
    lastName: "",
    identificationDocType: "",
    otherIdentificationDocType: "",
    identificationDocNumber: "",
    nuit: "",
    province: "",
    provinceId: null,
    district: "",
    districtId: null,
    neighborhood: "",
    street: "",
    block: "",
    houseNumber: "",
    phoneNumber: "",
    phoneNumber1: "",
    phoneNumber2: "",
    email: "",
    altContactPersonName: "",
    altContactPersonPhoneNumber: "",
    altContactPersonRelationship: "",
    name: null,
  });

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [candidateId, SetCandidateId] = useState(user?.candidateId);
  const [entitytype, setEntitytype] = useState([]);
  const [selectedEntitytype, setSelectedEntitytype] = useState();
  const [rows, setRows] = React.useState<RowInterface[]>([]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: false,
    },
    {
      field: "status",
      headerName: "Fill State",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Ações",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate(`/assessments/edit`, {
              state: { id: params.id, candidateId: candidateId },
            });
          }}
          label="Edit"
        />,
      ],
    },
  ];

  const handleSubmit = async (values: FormData) => {
    try {
      console.log("xxxx", values);
      await http.post(`/candidate`, values);
      toast.success(`Candidate updated successfully!`);
    } catch (error: any) {
      toast.error(error.message || "Error updating candidate");
    }
  };

  useEffect(() => {
    SetCandidateId(user?.candidateId);
    const fetchDataCandidate = async () => {
      try {
        const userId = user?.candidateId;
        const result = await http.get(`/candidate/` + userId);
        setCandidateFormData(result.data);
      } catch (error) {
        toast.error("Failed to fetch candidate data");
      }
    };

    fetchDataCandidate();
  }, [user]);

  return (
    <>
      <OlogaPageHeader
        title="Candidate Details"
        items={[{ label: "Candidates", url: "/Candidates/search" }]}
      />
      <Card variant="outlined" style={cardTyele}>
        <Typography variant="overline" component="h2">
          Candidate Details
        </Typography>
        <Formik
          initialValues={CandidateFormData}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="firstName"
                    label="First Name"
                    value={values.firstName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="lastName"
                    label="Last Name"
                    value={values.lastName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="identificationDocType"
                    label="ID Type"
                    value={values.identificationDocType}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="identificationDocNumber"
                    label="ID Number"
                    value={values.identificationDocNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="nuit"
                    label="Nuit"
                    value={values.nuit}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="province"
                    label="Province"
                    value={values.province}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="district"
                    label="District"
                    value={values.district}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="neighborhood"
                    label="Neighborhood"
                    value={values.neighborhood}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="street"
                    label="Street"
                    value={values.street}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="block"
                    label="Block"
                    value={values.block}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="houseNumber"
                    label="House Number"
                    value={values.houseNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="phoneNumber"
                    label="Phone Number"
                    value={values.phoneNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="phoneNumber1"
                    label="Phone Number 1"
                    value={values.phoneNumber1}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="phoneNumber2"
                    label="Phone Number 2"
                    value={values.phoneNumber2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="altContactPersonName"
                    label="Alt Contact Person Name"
                    value={values.altContactPersonName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="altContactPersonPhoneNumber"
                    label="Alt Contact Person Phone Number"
                    value={values.altContactPersonPhoneNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OlogaTextfield
                    name="altContactPersonRelationship"
                    label="Alt Contact Person Relationship"
                    value={values.altContactPersonRelationship}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <hr />
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <Typography variant="overline" component="h2">
                Assessments
              </Typography>
              <OlogaDataGrid
                url={`/assessments/status?candidateId=${candidateId}`}
                columns={columns}
              />
              <OlogaBackButton />
            </Card>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default CandidateViewPage;
