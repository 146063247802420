import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Formik, Field } from "formik";
import {
  Card as MuiCard,
  CardContent,
  Grid,
  Typography,
  TextField,
  Button,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import http from "../../utils/axios";
import { toolSchema } from "../../utils/formValidation";
import { toast } from "react-toastify";
import React from "react";

const Card = styled(MuiCard)(spacing);
interface EntityType {
  id: number;
  description: string;
}
interface ToolFormProps {
  tool?: any;
}

function ToolForm({ tool }: ToolFormProps) {
  const [selectedDescription, setSelectedDescription] = useState("");
  const [entitytype, setEntitytype] = useState<EntityType[]>([]);
  const [initialValues, setInitialValues] = useState({
    id: 0,
    name: "",
    description: "",
    assessment: {
      id: 0,
      name: "",
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (tool?.id) {
          const result = await http.get(`/tools/${tool.id}`);
          setSelectedDescription(result?.data?.description);
          setInitialValues(result.data);
        }
        const _entitytype = await http.get(`/simple-entity/entity-types`);
        setEntitytype(_entitytype.data);
      } catch (error) {
        toast.error("Error fetching data");
      }
    };
    fetchData();
  }, [tool?.id]);

  const handleSubmit = async (
    values: any,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      const _value = {
        id: values?.id,
        assessmentId: values?.assessment?.id,
        name: values?.name,
        description: getDescriptionIdByText(values?.description)?.toString(),
        status: "",
      };
      await http.post(`/tools/create`, _value);
      toast.success("Tool saved successfully");
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
      toast.error(error.message || "Error saving tool");
    }
  };

  const getDescriptionIdByText = (descriptionText: string): number | null => {
    const description = entitytype.find(
      (item: EntityType) => item.description === descriptionText
    );
    return description ? description.id : null;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={toolSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ handleSubmit, setFieldValue, values, errors, touched }) => (
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Typography variant="h6" gutterBottom>
                Tool Details
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    select
                    name="description"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    value={values.description}
                    onChange={(e: any) =>
                      setFieldValue("description", e.target.value)
                    }
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  >
                    {entitytype.map((option: any) => (
                      <MenuItem key={option.id} value={option.description}>
                        {option.description}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                justifyContent="flex-end"
                sx={{ mt: 2 }}
              >
                <Grid item>
                  <Button type="submit" variant="contained" color="primary">
                    {tool?.id ? "Update" : "Create"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export default ToolForm;
