import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  CardContent,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Formik, Form, Field, FormikHelpers, ErrorMessage } from "formik";
import * as Yup from "yup";
import http from "../../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface DemandMappingFormValues {
  id: number;
  candidateId: number;
  activitySector: string;
  analysCategory: string;
  gap: string;
  specificAditionalNeeds: string;
  comments: string;
}
interface dropdownDTO {
  text: string;
  value: number;
}
const GapAssessmentForm: React.FC<{
  onClose: () => void;
  dto: any;
  handleOpenGapList: () => void;
}> = ({ onClose, dto, handleOpenGapList }) => {
  const [initialValues, setInitialValues] = useState<DemandMappingFormValues>({
    id: 0,
    activitySector: "",
    analysCategory: "",
    gap: "",
    specificAditionalNeeds: "",
    comments: "",
    candidateId: dto.candidateId,
  });
  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const navigate = useNavigate();
  const [province, setProvince] = useState("");
  const [dropdown_activitySector, setDropdown_activitySector] = useState<
    dropdownDTO[]
  >([]);

  useEffect(() => {
    // const fetchData = async () => {
    //   const response = await http.get(`simple-entity/findDropdown/PROVINCE`);
    //   setDropdown_province(response.data);
    //   const ACTIVITY_SECTOR = await http.get(
    //     `simple-entity/findDropdown/ACTIVITY_SECTOR`
    //   );
    //   setDropdown_activitySector(ACTIVITY_SECTOR.data);
    // };
    // fetchData();
    // console.log(actionPlanDto);
    // if (assessmentScoreId) {
    //   const fetchData = async () => {
    //     try {
    //       if (actionPlanDto?.status != "No Action Plan found") {
    //         const response = await http.get(
    //           `/actionPlan/${actionPlanDto.actionPlanId}`
    //         );
    //         if (response.data) {
    //           setInitialValues(response.data);
    //         }
    //       }
    //     } catch (error) {
    //       console.error("Error fetching action plan:", error);
    //     }
    //   };
    //   fetchData();
    // }
  }, []);
  const handleSelectChange = (fieldName: any) => (event: any) => {
    const { value } = event.target;
    // initialValues.province = value;
    // setProvince(value);
    // setInitialValues((prevState) => ({
    //   ...prevState,
    //   [fieldName]: value,
    // }));
  };
  const handleSubmit = async (
    values: DemandMappingFormValues,
    actions: FormikHelpers<DemandMappingFormValues>
  ) => {
    try {
      console.log(values);
      values["candidateId"] = dto.candidateId;
      //   if (dto != null) {
      // if (values.id > 0) {
      // values["id"] = dto.actionPlanId;

      // await http.put(`/actionPlan/${dto.actionPlanId}`, values);
      // toast.success("Action Plan Updated successfully");
      // navigate("/assessment-admin/action-plan");

      // }
      //   } else {
      const data = { ...values };
      const response = await http.post("/gap-assessment", data);
      if (response.status === 200) {
        toast.success("Gap Created successfully");
        onClose(); // Fechar o modal após salvar
        handleOpenGapList();
      }
      //   }
    } catch (error) {
      console.error("Error creating/updating Gap assessment:", error);
      toast.error(
        "An error occurred while saving the gap-assessment, try again or contact system admin "
      );
    } finally {
      actions.setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    gap: Yup.string().required("Gap is required"),
    analysCategory: Yup.string().required("Analys Category is required"),
    // startDate: Yup.date().required("Start Date is required"),
    // endDate: Yup.date()
    //   .required("End Date is required")
    //   .min(Yup.ref("startDate"), "End Date can't be before Start Date"),
  });

  return (
    <>
      <Card>
        <CardContent>
          <Box>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ values, handleChange, isSubmitting, errors, touched }) => (
                <Form>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="analysCategory"
                          label="Categoria de Análise"
                          fullWidth
                          value={values.analysCategory}
                          onChange={handleChange}
                          error={
                            touched.analysCategory &&
                            Boolean(errors.analysCategory)
                          }
                          helperText={<ErrorMessage name="analysCategory" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="gap"
                          label="Gap"
                          fullWidth
                          value={values.gap}
                          onChange={handleChange}
                          error={touched.gap && Boolean(errors.gap)}
                          helperText={<ErrorMessage name="gap" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="specificAditionalNeeds"
                          label="Necessidades Adicionais Especificas"
                          fullWidth
                          value={values.specificAditionalNeeds}
                          onChange={handleChange}
                          error={
                            touched.specificAditionalNeeds &&
                            Boolean(errors.specificAditionalNeeds)
                          }
                          helperText={
                            <ErrorMessage name="specificAditionalNeeds" />
                          }
                        />
                      </Grid>
                      {/* <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="province-label">Província</InputLabel>
                          <Select
                            labelId="province-label"
                            value={initialValues.province}
                            label="Província"
                            onChange={handleSelectChange("province")}
                            // required
                            //   error={errors.province}
                          >
                            {dropdown_province.map((province) => (
                              <MenuItem
                                key={province.text}
                                value={province.text}
                              >
                                {province.text}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.province && (
                            <FormHelperText error>
                              Campo obrigatório
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid> */}
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="comments"
                          label="Comentarios"
                          fullWidth
                          value={values.comments}
                          onChange={handleChange}
                          error={touched.comments && Boolean(errors.comments)}
                          helperText={<ErrorMessage name="district" />}
                        />
                      </Grid>
                      {/* <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="location"
                          label="Localização"
                          fullWidth
                          value={values.location}
                          onChange={handleChange}
                          error={touched.location && Boolean(errors.location)}
                          helperText={<ErrorMessage name="location" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="executionPhase"
                          label="Fase de Execução"
                          fullWidth
                          value={values.executionPhase}
                          onChange={handleChange}
                          error={
                            touched.executionPhase &&
                            Boolean(errors.executionPhase)
                          }
                          helperText={<ErrorMessage name="executionPhase" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="responsable"
                          label="Responsável"
                          fullWidth
                          value={values.responsable}
                          onChange={handleChange}
                          error={
                            touched.responsable && Boolean(errors.responsable)
                          }
                          helperText={<ErrorMessage name="responsable" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="perpectiveDuration"
                          label="Duração"
                          fullWidth
                          value={values.perpectiveDuration}
                          onChange={handleChange}
                          error={
                            touched.perpectiveDuration &&
                            Boolean(errors.perpectiveDuration)
                          }
                          helperText={
                            <ErrorMessage name="perpectiveDuration" />
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="startDate"
                          label="Data de Início"
                          type="date"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={values.startDate}
                          onChange={handleChange}
                          error={touched.startDate && Boolean(errors.startDate)}
                          helperText={<ErrorMessage name="startDate" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="endDate"
                          label="Data de Fim"
                          type="date"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={values.endDate}
                          onChange={handleChange}
                          error={touched.endDate && Boolean(errors.endDate)}
                          helperText={<ErrorMessage name="endDate" />}
                        />
                      </Grid> */}
                      <Grid item xs={6}></Grid>
                      <Grid item xs={6}>
                        <Grid container justifyContent="flex-end">
                          <Box
                            mt={2}
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            <>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}
                              >
                                {values.id === 0 ? "Gravar" : "Atualizar"}
                              </Button>
                            </>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default GapAssessmentForm;
