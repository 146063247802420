import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Card as MuiCard, Grid, CardContent, Button } from "@mui/material";
import { spacing } from "@mui/system";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import http from "../../utils/axios";
import Table from "@mui/material/Table";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";

const Card = styled(MuiCard)(spacing);

interface RowInterface {
  name: string;
  description: string;
  id: number;
  status: string;
  assessmentId: any;
}

function CandidateToolRegistration() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [entitytype, setEntitytype] = useState([]);
  const [rows, setRows] = React.useState<RowInterface[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await http.get(`/tools/tool-register`);
        setRows(result?.data as RowInterface[]);

        const _entitytype = await http.get(`/simple-entity/entity-types`);
        setEntitytype(_entitytype.data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  return (
    <>
      <OlogaPageHeader
        title="Candidate Registration"
        items={[{ label: "Registration", url: "/candidates/create" }]}
      />

      <Grid container style={{ marginTop: "10px" }}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Descrição</TableCell>
                      <TableCell> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<VisibilityOutlinedIcon />}
                            onClick={() => {
                              navigate(`/tools/form`, {
                                state: {
                                  toolId: row.id,
                                  assessmentId: row.assessmentId,
                                  toolDesc: row.name,
                                  toolCode: row.description,
                                },
                              });
                            }}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <OlogaBackButton />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default CandidateToolRegistration;
