import React, { useState, useEffect } from "react";
import http from "../../utils/axios";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/dracula.css";
import "codemirror/mode/sql/sql";

interface PerformanceIndicator {
  id?: number;
  name: string;
  targetValue: number;
  unitOfMeasure: string;
  period: string;
  indicatorType: string;
  sqlQuery?: string;
}

interface PerformanceIndicatorFormProps {
  indicator: PerformanceIndicator | null;
  onClose: () => void;
}

const PerformanceIndicatorForm: React.FC<PerformanceIndicatorFormProps> = ({
  indicator,
  onClose,
}) => {
  const [formValues, setFormValues] = useState<PerformanceIndicator>({
    name: "",
    targetValue: 0,
    unitOfMeasure: "",
    period: "",
    indicatorType: "",
    sqlQuery: "",
  });

  useEffect(() => {
    const populateData = async () => {
      if (indicator) {
        setFormValues(indicator);
      }
    };
    populateData();
  }, [indicator]);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCodeChange = (editor: any, data: any, value: string) => {
    setFormValues({
      ...formValues,
      sqlQuery: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await http.post("/performanceIndicator", formValues);
      if (indicator?.id) {
        toast.success("Indicator updated successfully");
      } else {
        toast.success("Indicator created successfully");
      }
      onClose();
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while saving the indicator");
    }
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        {indicator?.id
          ? "Edit Performance Indicator"
          : "Create Performance Indicator"}
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <TextField
            name="name"
            label="Name"
            value={formValues.name}
            onChange={handleChange}
            required
            fullWidth
          />
          <TextField
            name="targetValue"
            label="Target Value"
            type="number"
            value={formValues.targetValue}
            onChange={handleChange}
            required
            fullWidth
          />
          <TextField
            name="unitOfMeasure"
            label="Unit of Measure"
            value={formValues.unitOfMeasure}
            onChange={handleChange}
            required
            fullWidth
          />
          <FormControl fullWidth required>
            <InputLabel id="period-label">Period</InputLabel>
            <Select
              labelId="period-label"
              name="period"
              value={formValues.period}
              onChange={handleChange}
            >
              <MenuItem value="Annually">Annually</MenuItem>
              <MenuItem value="Quarterly">Quarterly</MenuItem>
              <MenuItem value="Monthly">Monthly</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth required>
            <InputLabel id="indicatorType-label">Indicator Type</InputLabel>
            <Select
              labelId="indicatorType-label"
              name="indicatorType"
              value={formValues.indicatorType}
              onChange={handleChange}
            >
              <MenuItem value="OSS">OSS</MenuItem>
              <MenuItem value="Beneficiaries">Beneficiaries</MenuItem>
            </Select>
          </FormControl>
          <Box
            sx={{
              border: "1px solid #ddd",
              borderRadius: 1,
              overflow: "hidden",
            }}
          >
            <CodeMirror
              value={formValues.sqlQuery || ""}
              options={{
                mode: "sql",
                theme: "dracula", // Defina o tema para Dracula
                lineNumbers: true,
                lineWrapping: true,
              }}
              onBeforeChange={handleCodeChange}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          {indicator?.id ? "Update" : "Create"}
        </Button>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PerformanceIndicatorForm;
