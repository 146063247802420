import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Divider,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import axios from "../../utils/axios";
import OlogaStats from "../../components/misc/OlogaStats";

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const paperStyle = {
  position: "absolute",
  maxWidth: "70%",
  backgroundColor: "background.paper",
  border: "2px solid #bb86fc",
  padding: 4,
  overflow: "hidden",
};

type StockMetadataType = {
  eventProductId: string;
  vendorId: string;
  eventId: string;
  eventName: string;
  productName: string;
  imageUri: string;
  stockIn: number;
  stockOut: number;
  balance: number;
  manualStockCount: number;
};

const StockDetailModal: React.FC<{
  open: boolean;
  onClose: any;
  stockMetadata: StockMetadataType;
}> = ({ open, onClose, stockMetadata }) => {
  const handleClose = () => {
    onClose();
  };
  const [slide, setSlide] = React.useState(false);
  const [eventProducts, setEventProducts] = useState([]);

  useEffect(() => {
    open && getDetailedStockList(stockMetadata);
  }, [open, stockMetadata]);

  const getDetailedStockList = async (stockMetadata: StockMetadataType) => {
    const response = await axios.get(
      `stock/recon/event/${stockMetadata.eventId}/vendor/${stockMetadata.vendorId}/product/${stockMetadata.eventProductId}`
    );
    console.log(response.data);
    response &&
      setEventProducts(
        response.data.map((r: any) => {
          return {
            id: r.eventProdctId,
            name: r.productDescription,
            stationName: r.stationName,
            imageUri: r.imageUri,
            stockIn: r.transferIn,
            stockOut: r.transferOut,
            finalOpeningStock: r.finalOpeningStock,
            finalStockManual: r.finalStockManual,
            difference: r.finalStockManual
              ? r.finalStockManual
              : 0 - r.finalOpeningStock,
            productSize: r.productSizeDescription,
          };
        })
      );
  };

  return (
    <Modal open={open} onClose={handleClose} sx={modalStyle}>
      <Box sx={paperStyle}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <IconButton onClick={handleClose} style={{ float: "right" }}>
              <CloseIcon />
            </IconButton>
            {/* <Typography variant="h4" gutterBottom>
              Stock Details
            </Typography> */}
            <Typography variant="h3">
              {stockMetadata.eventProductId} - {stockMetadata.productName}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {stockMetadata.eventName}
            </Typography>
          </Grid>

          <Grid container spacing={6} mt={2}>
            <Grid item xs={12} lg={2}>
              <img
                src={stockMetadata.imageUri}
                alt="Product"
                style={{ borderRadius: "8px", width: "90%" }}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <OlogaStats
                title="Initial Stock"
                value={stockMetadata.stockIn ? stockMetadata.stockIn : "N/A"}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <OlogaStats
                title="Stock out"
                value={stockMetadata.stockOut ? stockMetadata.stockOut : "N/A"}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <OlogaStats
                title="Manual"
                value={
                  stockMetadata.manualStockCount
                    ? stockMetadata.manualStockCount
                    : "N/A"
                }
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <OlogaStats
                title="Balance"
                value={stockMetadata.balance ? stockMetadata.balance : "N/A"}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <OlogaStats
                title="Difference"
                value={stockMetadata.stockIn - stockMetadata.manualStockCount}
              />
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <Divider style={{ margin: "20px 0" }} />
          </Grid> */}

          <Grid item xs={12}>
            {/* Table */}
            <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>STATION NAME</TableCell>
                    <TableCell>SIZE</TableCell>
                    <TableCell>STOCK IN</TableCell>
                    <TableCell>STOCK OUT</TableCell>
                    <TableCell>{`FINAL STOCK`}</TableCell>
                    <TableCell>{`FINAL STOCK (MANUAL COUNT)`}</TableCell>
                    <TableCell>DIFFERENCE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventProducts.map(
                    ({
                      id,
                      stationName,
                      name,
                      stockIn,
                      stockOut,
                      finalOpeningStock,
                      finalStockManual,
                      difference,
                      productSize,
                    }) => (
                      <TableRow key={id + name + stockIn}>
                        <TableCell>{stationName}</TableCell>
                        <TableCell>{productSize || "N/A"}</TableCell>
                        <TableCell> {stockIn}</TableCell>
                        <TableCell> {stockOut}</TableCell>
                        <TableCell>{finalOpeningStock}</TableCell>
                        <TableCell>{finalStockManual}</TableCell>
                        <TableCell>{difference}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
export default StockDetailModal;
