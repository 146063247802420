import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Card as MuiCard, Grid, CardContent } from "@mui/material";
import { spacing } from "@mui/system";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import http from "../../utils/axios";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React from "react";
import { toolSchema } from "../../utils/formValidation";
import ToolForm from "./ToolForm";
import OlogaSelect from "../../components/select/OlogaSelect";
import OlogaCancelButton from "../../components/Buttons/OlogaCancelButton";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import axios from "axios";
import { Try } from "@mui/icons-material";

const Card = styled(MuiCard)(spacing);
interface RowInterface {
  id: number;
  name: string;
  description: string;
  assessment_id: number;
}
interface Answer {
  id: number;
  name: string;
  description: string;
  Question: { id: number };
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ToolUpdate() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openAnwers, setOpenAnwers] = useState(false);
  const handleAnwersOpen = () => setOpenAnwers(true);
  const handleAnwersClose = () => setOpenAnwers(false);
  const [rows, setRows] = useState<RowInterface[]>([]);
  const [questiontype, setQuestiontype] = useState([]);
  const [selectedQuestiontype, setSelectedQuestiontype] = useState("");
  const handleQuestiontypeChange = (value: any) => {
    setSelectedQuestiontype("Escolha Múltipla");
    setSelectedQuestiontype(value);
  };
  const [questioncategory, setquestioncategory] = useState([]);
  const [selectedQuestioncategory, setSelectedQuestioncategory] = useState();
  const [selecttedValueString, SetselecttedValueString] = useState("");
  const handleQuestioncategoryChange = (value: any) => {
    setSelectedQuestioncategory(value);

    /*     if (findedCategory) {
      setSelectedQuestiontype(findedCategory.description);
    } */
  };

  const [question, setQuestion] = useState({
    id: 0,
    description: "",
    name: "",
    categoryId: "",
    typeId: "",
    toolId: state,
    answers: [],
  });

  const [answers, setAnswers] = useState<Answer[]>([]);
  const [options, setOptions] = useState([""]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _questiontype = await http.get(`/simple-entity/questiontypes`);
        setQuestiontype(_questiontype.data);
        const _questioncategory = await http.get(
          `/simple-entity/questioncategory`
        );
        setquestioncategory(_questioncategory.data);
        const _questions = await http.get(`/questions/${state}`);
        setRows(_questions.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleOptionChange = (index: any, event: any) => {
    const newOptions = [...options];
    newOptions[index] = event.target.value;
    setOptions(newOptions);
  };

  const handleAnswerOptionChange = (index: any, event: any) => {
    const newOptions = [...answers];
    const new_value = event.target.value;

    const chengedOne = answers[index];
    chengedOne.name = new_value;
    chengedOne.description = new_value;

    newOptions[index] = chengedOne;

    setAnswers(newOptions);
  };

  const addOption = () => {
    setOptions([...options, ""]);
  };

  const updateAnswer = async (answer: Answer) => {
    try {
      const response = await http.post(`/answers/update/`, answer);
      toast.success(`Answer updated successfully!`);
    } catch (error: any) {
      toast.error(`Erro updating Answer`);
    }
  };
  const RemoveAnswer = async (answer: Answer) => {
    try {
      const response = await http.post(`/answers/remove/`, answer);
      toast.success(`Answer removed successfully!`);
    } catch (error: any) {
      toast.error(
        `Error deleting the Answer, possibly the Answer has already been used in some tool.`
      );
    }
  };

  const readAwnsers = async (questionId: number) => {
    console.log("readAwnsers");
    const response = await http.get(`/answers/findByQuestionId/${questionId}`);
    setAnswers(response.data);
    console.log("Id da questão", response.data);
    console.log("Id da questão", answers);
  };
  /*   
    const retrieveAssessmentScoreItems = async (
    toolId: number,
    assessmentScoreId: number
  ) => {
    if (state.id) {
      const response = await axios.get(
        `assessments/getAssessmentScoreItems/${toolId}/${assessmentScoreId}`
      );

      setAssessmentAnswers(response.data);
    }
  };
  const readAwnsers = async (questionId: number) => {
    const _questiontype = await http.get(
      `answers/findByQuestionId/${questionId}`
    );
    console.log("Id da questão", questionId);
  }; */

  const removeOption = (index: any) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      values.answers = options;
      await http.post(`/questions/create/`, values);
      console.log("handleSubmit", values);
      console.log("options", options);
      // todo clean form after submit
      handleClose();
      // resetForm();
      toast.success(`question saved successfully!`);
      navigate("/tools/edit", { state: state });
      window.location.reload();
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
      toast.error(error.message || "Error saving vendor");
    }
  };

  return (
    <>
      <OlogaPageHeader
        title="Update Tool"
        items={[{ label: "Tools", url: "/tools/search" }]}
      />
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <ToolForm
                tool={{
                  id: state,
                  name: "",
                  description: "",
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "10px" }}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    onClick={handleOpen}
                    style={{ marginBottom: "10px" }}
                  >
                    Create Question
                  </Button>
                </div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Formik
                      initialValues={question}
                      validationSchema={toolSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ handleSubmit, status, errors }) => (
                        <Card>
                          <CardContent>
                            <form onSubmit={handleSubmit}>
                              <Typography variant="h6" gutterBottom>
                                Create Question
                              </Typography>
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <OlogaTextfield name="name" label="Name" />
                                </Grid>
                                <Grid item xs={12}>
                                  <OlogaTextfield
                                    name="description"
                                    label="Description"
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <OlogaSelect
                                    name="categoryId"
                                    options={questioncategory}
                                    label="category"
                                    setValue={handleQuestioncategoryChange}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <OlogaSelect
                                    name="typeId"
                                    options={questiontype}
                                    label="type"
                                    setValue={handleQuestiontypeChange}
                                  />
                                </Grid>
                              </Grid>
                              <>
                                <div>
                                  {options.map((option, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <OlogaTextfield
                                        value={option}
                                        onChange={(event) =>
                                          handleOptionChange(index, event)
                                        }
                                        name={`Option ${index + 1}`}
                                        label={`Option ${index + 1}`}
                                      />
                                      <Button
                                        variant="contained"
                                        onClick={addOption}
                                        style={{ marginLeft: "10px" }}
                                      >
                                        Add Option
                                      </Button>
                                      <Button
                                        variant="contained"
                                        onClick={() => removeOption(index)}
                                        style={{ marginLeft: "10px" }}
                                      >
                                        Remove Option
                                      </Button>
                                    </div>
                                  ))}
                                </div>
                              </>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    handleSubmit();
                                  }}
                                  style={{ marginBottom: "10px" }}
                                >
                                  Save question
                                </Button>
                              </div>
                            </form>
                          </CardContent>
                        </Card>
                      )}
                    </Formik>
                  </Box>
                </Modal>
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.assessment_id}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>
                          <VisibilityOutlinedIcon
                            onClick={() => {
                              /*      navigate(`/tools/edit`, {
                                state: row.assessment_id,
                              }); */
                              handleAnwersOpen();
                              readAwnsers(row.id);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Card style={{ marginTop: "10px" }}>
        <CardContent>
          <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <>
              <OlogaButton label={"Back"} onClick={() => navigate(-1)} />
            </>
          </Box>
        </CardContent>
      </Card>
      <Modal
        open={openAnwers}
        onClose={handleAnwersClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Formik
            initialValues={question}
            validationSchema={toolSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, status, errors }) => (
              <Card>
                <CardContent>
                  <form onSubmit={handleSubmit}>
                    <div>
                      {answers.map((answer: Answer, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <OlogaTextfield
                            value={answer.description}
                            onChange={(event) =>
                              handleAnswerOptionChange(index, event)
                            }
                            name={`answer ${index + 1}`}
                            label={`answer ${index + 1}`}
                          />
                          <Button
                            variant="contained"
                            onClick={() => {
                              updateAnswer(answer);
                            }}
                            style={{ marginLeft: "10px" }}
                          >
                            Update
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              RemoveAnswer(answer);
                            }}
                            style={{ marginLeft: "10px" }}
                          >
                            Remove
                          </Button>
                        </div>
                      ))}
                    </div>
                  </form>
                </CardContent>
              </Card>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
}

export default ToolUpdate;
