import React, { useState } from "react";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Card as MuiCard,
  Grid,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { Box, spacing } from "@mui/system";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import DemandMappingForm from "./DemandMappingForm";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ToolDemandMapping: React.FC = () => {
  const Card = styled(MuiCard)(spacing);
  const navigate = useNavigate();
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [demandDto, setDemandDto] = useState({});
  const handleOpenFeedbackModal = () => setOpenFeedbackModal(true);
  const handleCloseFeedbackModal = () => setOpenFeedbackModal(false);

  const columns: GridColDef[] = [
    {
      field: "reference",
      headerName: "Ref",
      flex: 1,
      editable: false,
    },
    {
      field: "name",
      headerName: "Descrição do Projecto/Oportunidade",
      flex: 4,
      editable: false,
    },

    {
      field: "province",
      headerName: "Provincia",
      flex: 2,
      editable: false,
    },
    {
      field: "district",
      headerName: "Distrito",
      flex: 2,
      editable: false,
    },
    {
      field: "location",
      headerName: "Localização",
      flex: 1,
      editable: false,
    },
    {
      field: "subActivitySector",
      headerName: "Sub-Sector de Actividade",
      flex: 3,
      editable: false,
    },
    {
      field: "executionPhase",
      headerName: "Duração Prevista",
      flex: 2,
      editable: false,
    },
    {
      field: "startDate",
      headerName: "Data de Inicio",
      flex: 2,
      editable: false,
    },
    {
      field: "endDate",
      headerName: "Data de Fim",
      flex: 2,
      editable: false,
    },
  ];

  return (
    <React.Fragment>
      <OlogaPageHeader
        title={"Mapeamento de Demanda"}
        buttonLabel="Adicionar Nova Demanda"
        onClick={handleOpenFeedbackModal}
      ></OlogaPageHeader>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid url={"/demand"} columns={columns} />
          </Card>

          <Modal open={openFeedbackModal} onClose={handleCloseFeedbackModal}>
            <Box sx={style}>
              <Typography variant="h6" component="h2" mb={2}>
                Formulário de Mapeamento de Demanda
              </Typography>
              <DemandMappingForm
                onClose={handleCloseFeedbackModal}
                dto={demandDto}
              />
            </Box>
          </Modal>
          <OlogaBackButton />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ToolDemandMapping;
